import React from "react";
import i18n from "../../i18n";
import VectorTabDefault from "../result/VectorTab";
import SimpleTab from "./SimpleTab";

export default class VectorTab extends VectorTabDefault {

  render() {
    if (this.state.isRefreshView) {
      if (!this.state.task || this.state.task.status.toLowerCase() === "inprogress") {
        return <div className="creative-holder">
          <div className="creative-holder-placeholder" />
          <div className="loader-video">
            <span className="item-loader first-item-loader" />
            <span className="item-loader second-item-loader" />
            <span className="item-loader third-item-loader" />
          </div>
        </div>;
      }

      if (this.state.task.status.toLowerCase() === "ok") {
        return <div className="choose-page">
          <div className="container">
            <h2>{i18n.t("vector_tab_refresh__title")}</h2>
            <p>{i18n.t("vector_tab_refresh__subtitle")}</p>
          </div>
          <div className="choose-items">
            {this.state.task.results.map((resultItem) => <div className="choose-item-container" key={resultItem.templateId}>
              <div className="choose-item">
                <img
                  src={resultItem.resultUrl}
                  alt={resultItem.templateId}
                  onClick={() => this.handleSelectTemplate(resultItem.templateId)} />
              </div>
            </div>)}
          </div>
        </div>;
      }

      return <div>task failed</div>;
    }

    const tabProps = {...this.props};
    tabProps.onRefreshButtonClick = this.handleRefreshButtonClick;
    tabProps.canBeRefreshed = true;

    return <SimpleTab {...tabProps} />;
  }
}
