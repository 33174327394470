import React from "react";
import i18n from "../i18n";
import {webviewOpenBrowser} from "../utils/webview";
import routes from "../routes";
import {hitEvent, hits} from "../utils/log";
import {assetUrl, prefixRouteForSite} from "../utils/etc";
import {SvgSprite} from "../components/SvgSprite";
import * as api from "../utils/api";

const CONTACT_US_EMAIL = "contact@toonme.com";

export default class HiringPage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      contactUsForm: {
        isHidden: true,
        isCompleted: false,
        name: {
          value: "",
          error: undefined,
        },
        background: {
          value: "",
          error: undefined,
        },
        email: {
          value: "",
          error: undefined,
        },
        cv: {
          value: "",
          error: undefined,
        },
      },
    };
  }

  componentDidMount() {
    hitEvent(hits.PAGE_HIRING_JAPAN);
  }

  handleContactUsClick = (e) => {
    hitEvent(hits.HIRING_EMAIL_CLICK);

    if (window.clientConfig.isWebview) {
      e.preventDefault();
      webviewOpenBrowser("mailto:" + CONTACT_US_EMAIL);
    }
  };

  handleCloseClick = () => {
    if (this.props.location.state && this.props.location.state.nextPath) {
      this.props.history.push(this.props.location.state.nextPath, {skipAd: true});
    } else {
      this.props.history.push(routes.INDEX);
    }
  };

  setContactUsFormFieldState = (field, value, error) => {
    this.setState({
      contactUsForm: {
        ...this.state.contactUsForm,
        [field]: {value, error},
      }
    });
  };

  setContactUsFormFieldError = (field, error) => {
    this.setState({
      contactUsForm: {
        ...this.state.contactUsForm,
        [field]: {
          ...this.state.contactUsForm[field],
          error,
        }
      }
    });
  };

  handleContactUsFormShowClick = () => {
    hitEvent(hits.HIRING_EMAIL_CLICK);

    this.setState({
      contactUsForm: {
        ...this.state.contactUsForm,
        isHidden: false,
      }
    });
  };

  handleContactUsFormShowSubmit = (e) => {
    e.preventDefault();

    if (this.state.contactUsForm.name.value.length === 0) {
      this.setContactUsFormFieldError("name", i18n.t("contact_us_form_name__error_empty"));
      return;
    }

    if (this.state.contactUsForm.email.value.length === 0) {
      this.setContactUsFormFieldError("email", i18n.t("contact_us_form_email__error_empty"));
      return;
    }

    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.state.contactUsForm.email.value)) {
      this.setContactUsFormFieldError("email", i18n.t("contact_us_form_email__error_invalid_format"));
      return;
    }

    hitEvent(hits.HIRING_SEND_FORM);

    this.setState({
      contactUsForm: {
        ...this.state.contactUsForm,
        isCompleted: true,
      }
    });

    api.contactUs(this.state.contactUsForm.name.value, this.state.contactUsForm.email.value)
        .then((res) => {
          /* omit */
        })
        .catch((err) => {
          console.error(err);
          this.props.history.replace(prefixRouteForSite(routes.ERROR, this.props.site));
        });
  };

  renderBanner = () => {
    return <main className="hiring-page">
      <div className="container">
        <button className="btn-close" onClick={this.handleCloseClick}>
          <SvgSprite viewBox="0 0 16 16" icon="icon-close" />
        </button>

        <h2 className="main-page-title" dangerouslySetInnerHTML={{__html:i18n.t("banner_title")}} />
        <p dangerouslySetInnerHTML={{__html:i18n.t("banner_text1")}} />
        <p dangerouslySetInnerHTML={{__html:i18n.t("banner_text2")}} />
        {/* <p className="text-link" dangerouslySetInnerHTML={{__html:i18n.t("banner_text3")}} /> */}
        {/* <a href={`mailto:${CONTACT_US_EMAIL}`}
           dangerouslySetInnerHTML={{__html: CONTACT_US_EMAIL}}
           onClick={this.handleContactUsClick} /> */}
        <button className="btn-show-contacts" onClick={this.handleContactUsFormShowClick}>{i18n.t("contact_us_form_show")}</button>

        <HiringView />
      </div>
    </main>;
  }

  renderContactForm = () => {
    const formTitle = this.state.contactUsForm.isCompleted
        ? i18n.t("contact_us_form_completed_title")
        : i18n.t("contact_us_form_title");
    const formSubtitle = this.state.contactUsForm.isCompleted
        ? i18n.t("contact_us_form_completed_subtitle")
        : i18n.t("contact_us_form_subtitle");

    return <main className="contact-page">
      <div className="container">
        <h2 dangerouslySetInnerHTML={{__html:formTitle}} />
        <p dangerouslySetInnerHTML={{__html:formSubtitle}} />
        <form
            hidden={this.state.contactUsForm.isCompleted}
            onSubmit={this.handleContactUsFormShowSubmit}>
          <div className={"form-container " + (this.state.contactUsForm.name.error ? "form-error" : "")}>
            <input
                type="text"
                className="form__input form__name"
                value={this.state.contactUsForm.name.value}
                onChange={(e) => this.setContactUsFormFieldState("name", e.target.value)} />
            <label className="label" dangerouslySetInnerHTML={{__html:i18n.t("contact_us_form_label_name")}} />
            <span
                hidden={!this.state.contactUsForm.name.error}
                className="form-error-message"
                dangerouslySetInnerHTML={{__html: this.state.contactUsForm.name.error}} />
          </div>
          <div className={"form-container " + (this.state.contactUsForm.email.error ? "form-error" : "")}>
            <input
                type="email"
                className="form__input form__email"
                value={this.state.contactUsForm.email.value}
                onChange={(e) => this.setContactUsFormFieldState("email", e.target.value)} />
            <label className="label" dangerouslySetInnerHTML={{__html:i18n.t("contact_us_form_label_email")}} />
            <span
                hidden={!this.state.contactUsForm.email.error}
                className="form-error-message"
                dangerouslySetInnerHTML={{__html: this.state.contactUsForm.email.error}} />
          </div>
          {/*<div className="form-file">
            <label class="label-attach">
              <SvgSprite viewBox="0 0 16 16" icon="icon-attach" />
              <span dangerouslySetInnerHTML={{__html:i18n.t("contact_us_form_cv")}} />
              <input type="file" className="form-cv" />
            </label>
          </div>*/}
          <div className="btns-container">
            <button className="btn-send" dangerouslySetInnerHTML={{__html: i18n.t("contact_us_form_submit")}}/>
          </div>
        </form>
        {/*<div className="btns-container">
          <button className="btn-return"  onClick={this.handleCloseClick}>
            <SvgSprite viewBox="0 0 32 54" icon="icon-back" />
            <span dangerouslySetInnerHTML={{__html: i18n.t("contact_us_form_close")}}/>
          </button>
        </div>*/}
      </div>
      <button className="btn-close" onClick={this.handleCloseClick}>
        <SvgSprite viewBox="0 0 16 16" icon="icon-close" />
      </button>
    </main>;
  }

  render() {
    return this.state.contactUsForm.isHidden ? this.renderBanner() : this.renderContactForm();
  }
}

export function HiringView() {
  const jpg = [
    assetUrl(`assets/img/bg/img_414.jpg`) + " 1x",
    assetUrl(`assets/img/bg/img_414@2x.jpg`) + " 2x",
    assetUrl(`assets/img/bg/img_414@3x.jpg`) + " 3x"
  ].join(", ");

  const webp = [
    assetUrl(`assets/img/bg/img_414.webp`) + " 1x",
    assetUrl(`assets/img/bg/img_414@2x.webp`) + " 2x",
    assetUrl(`assets/img/bg/img_414@3x.webp`) + " 3x"
  ].join(", ");

  return <picture>
    <source srcSet={webp} type="image/webp" />
    <img srcSet={jpg} alt="Demo" />
  </picture>;
}