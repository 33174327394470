module.exports = {
  "strings": {
    "try_another_photo": "Prova un’altra foto",

    "landing__title_part_1": "Woo-hoo!",
    "landing__title_part_2": "È il momento di vivere il tuo sogno a cartoni animati, tante volte.",
    "landing__subtitle": "Dai semplicemente la tua foto all'IA 😜",
    "landing__select_photo_button": "Scegli la foto",
    "landing__get_the_app_button": "Scarica l'app",
    "loading__uploading": "Caricamento...",

    "landing__title_labs": "ToonMe.com LABS",
    "landing__subtitle_labs": "Ti trovi in un ambiente sperimentale speciale al momento. Per vedere gli stili originali, torna alla versione principale qui sotto.",
    "landing__labs_back_button": "Torna a ToonMe Classic",
    "landing__labs_choice": "Oppure prova i nostri stili sperimentali su <span>ToonMe&nbsp;LABS</span>",

    "result__labs_tab_title": "Welcome to ToonMe LABS",
    "result__labs_tab_message": "Stiamo sperimentando nuovi stili e hai la possibilità di dare un'occhiata! Puoi tornare agli stili originali in qualsiasi momento.",
    "result__labs_tab_button": "Proviamo",
    "result__tab_classic_title": "Ti piacciono gli effetti sperimentali?",
    "result__tab_classic_info": "Rimani qui o torna alla versione principale di ToonMe",
    "result__tab_classic_btn": "Torna a ToonMe Classic",

    "processing_text_1": "Ingiallimento della tua pelle in corso...🖌️",
    "processing_text_2": "Cartonizzazione dei tuoi lineamenti in corso...",
    "processing_text_3": "Sembri una principessa Disney! No, scherzo... oppure no? 🙈",
    "processing_text_4": "Questo è il posto in cui i sogni diventano CARTONI 🦄",
    "processing_text_5": "Stiamo CARTONIZZANDO con precisione la tua foto!",
    "processing_text_6": "La cartonizzazione è in corso!",
    "processing_text_7": "A Walt Disney sarebbe piaciuta! ✨",

    "collage__select_photo": "Prova la tua foto",
    "collage__download": "Scarica in HD",
    "collage__refresh": "Aggiorna",
    "collage__more_styles": "Altri stili",

    "download_modal__title": "Non fermarti qui!",
    "download_modal__message": "Come ci si sente a essere una stella dei cartoni animati? Condividi il tuo momento di notorietà su Instagram, postando il risultato che ti piace di più!",
    "download_modal__message_wait": "Creazione del risultato in HD...",
    "download_modal__message_ready": "La versione in HD è pronta. Download avviato...",
    "download_modal__dismiss_button": "Capito",
    "download_modal__download_button": "Scarica di nuovo",

    "error__error": "Errore",

    "internal_error": "Si è verificato un errore...",
    "error_overload_webview": "Siamo terribilmente spiacenti, ma stiamo riscontrando un enorme sovraccarico sui server, a causa degli elevati download dell'app. Ti preghiamo di utilizzare la scheda Toon Effects come alternativa, oppure di provare a ritornare più tardi: stiamo lavorando sodo per risolvere tutto in breve tempo.",
    "error_overload_web": "Siamo terribilmente spiacenti, ma stiamo riscontrando un enorme sovraccarico sui server, a causa degli elevati download dell'app. Ti preghiamo di ritornare un po' più tardi: stiamo lavorando sodo per risolvere tutto in breve tempo.",

    "rateapp_dialog__title": "Ti piace ToonMe?",
    "rateapp_dialog__message-bold": "Assegna delle stelle all'app e aiuta anche altri utenti a scoprirci e apprezzarci!",
    "rateapp_dialog__message": "P.S. Ci aiuterebbe anche ripristinare le valutazioni, influenzate dai problemi ai server avuti dopo che ToonMe è diventata virale. 🙏",
    "rateapp_dialog__rate_button": "Valuta!",
    "rateapp_dialog__skip_button": "Salta",

    "btn_store__title":"<span class=\"btn-store-title\">Scarica da</span><span class=\"btn-store-name\">{{market}}</span>",
    "btn_store__ios":"App Store",
    "btn_store__android":"Google Play",
    "btn_cancel": "Annulla e salva<br />in qualità standard",
    "btn_clone": "Beware of cheap clone!",
    "btn_contacts_form": "Contattaci",
    "btn_back": "back",

    "vector_tab_refresh__title": "Ce l'hai quasi fatta!",
    "vector_tab_refresh__subtitle": "Scegli un ritratto",

    "updated_at": "Aggiornato con nuovi effetti il <span class=\"updated-at-date\">{{date}}</span>",

    "warning_clones": "AVVERTENZA!",
    "warning_clones_link": "FAI ATTENZIONE A FALSE COPIE A BASSO COSTO",

    "snap_dialog__title": "Prima-dopo, o semplicemente CARTOON?",
    "snap_dialog__message": "Scegli come convidividerlo su Shapchat!",
    "snap_dialog__share_button": "Condividi",
    "snap_dialog__back_button": "Indietro",

    "title_contacts_form": "Dagli sviluppatori di Photo Lab, l'app fotografica per iOS e Android con 260 mln di download... e continuano ad aumentare!",

    "toggle_watermark_tooltip__text": "Toccare il logo per rimuoverlo",
    "toggle_watermark_on": "Logo sì",
    "toggle_watermark_off": "Logo no",

    "remove_logo_modal__title": "Mantenerlo o non mantenerlo…",
    "remove_logo_modal__message": "Ehi, lo sapevi che il logo fa parte del design di questo effetto? Scegli di mantenerlo, ne saremo felici.",
    "remove_logo_modal__btn_cancel": "Mantieni il logo",
    "remove_logo_modal__btn_remove": "Rimuovilo comunque",

    // v2
    "landing__toonme_on_instagram": "#ToonMe su Instagram",

    "creative_failed_message": "Ops, si è verificato un errore con questo CARTONE. 🤔 Scegli un altro stile o riprova.",
    "creative_failed_retry_btn": "Riprova",

    "refresh_body__btn_body": "Scegli lo stile del corpo",
    "refresh_body__btn_head": "Scegli lo stile della testa",
    "button_choice_gender_male": "da uomo",
    "button_choice_gender_female": "da donna",
    "button_choice_gender_text": "stili",
  },
};
