import React from 'react';
import {generatePath} from "react-router";
import routes from "../../routes";
import Loading from "../../components/Loading";
import HomeButton from "../../components/HomeButton";
import AppContext from "../../contexts/AppContext";
import * as creativeUtils from "../../utils/creative";
import {getLocationQueryObject} from "../../utils/text";
import {hitEvent, hits, logEvent, userEvents} from "../../utils/log";
import * as webviewUtils from "../../utils/webview";
import {debounce, prefixRouteForSite, when} from "../../utils/etc";
import clientStorage from "../../utils/client-storage";
import SimpleTab from "./SimpleTab";
import LabsTab from "./LabsTab";
import UniteTab from "./UniteTab";
import ClassicTab from "./ClassicTab";
import VectorTab from "./VectorTab";
import RemoveLogoModal from "./RemoveLogoModal";
import DownloadModal from "./DownloadModal";
import LoveTab from "./LoveTab";
import LoadingV2 from "../../components/LoadingV2";
import {collageGroupCreatives, creativeGroups} from "../../photolab/groups";
import {creativesConfigs} from "../../photolab/config";
import Creative from "../../photolab/Creative";
import CreativeConfig from "../../photolab/CreativeConfig";
import {mapNewCreativesToOld} from "../../utils/creative";
import Processing from "../../photolab/Processing";
import BodyTab from "./BodyTab";
import {webviewOpenBrowser} from "../../utils/webview";
import {UNITE_COM_LINK, UNITE_COM_TAB_LINK} from "../../utils/constants";

const SHARE_PROVIDER_SNAPCHAT = "snapchat";
const SHARE_PROVIDER_FACEBOOK = "facebook";
const SHARE_PROVIDER_INSTAGRAM = "instagram";

export default class ResultPage extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      file: null,
      tabs: [],
      creatives: null,
      tab: creativeGroups.DISNEY,
      defaultTab: creativeGroups.DISNEY,
    };

    this.hitProcessingFailedOnResultPageIsTriggered = false;

    if (clientStorage.getProWatermarkShouldBeRemoved()) {
      const choiceAt = clientStorage.getProWatermarkRemoveChoiceAt();
      if (Date.now() - choiceAt >= 86400000) {
        clientStorage.setProWatermarkShouldBeRemoved(false);
        clientStorage.setProWatermarkTooltipIsHidden(false);
      }
    }

    this.pageContainerRef = null;
    this.pageContainerRefObserver = new MutationObserver(() => {
      if (this.pageContainerRef) {
        this.pageContainerRef.style.height = "";
      }
    });
  }

  componentDidMount() {
    window.processingManager.addOnProcessingChangeHandler(this.handleProcessingChanged);

    if (window.processingManager.processing === null) {
      const restoredProcessing = window.processingManager.restore();
      if (restoredProcessing && restoredProcessing.site === this.props.site) {
        window.processingManager.start(restoredProcessing);
      } else {
        this.props.history.replace(routes.INDEX);
        return;
      }
    }

    this.handleProcessingChanged();
  }

  componentWillUnmount() {
    window.processingManager.removeOnProcessingChangeHandler(this.handleProcessingChanged);
  }

  handlePageContainerRefChange = (node) => {
    this.pageContainerRef = node;

    if (this.pageContainerRef) {
      this.pageContainerRefObserver.observe(this.pageContainerRef, {
        attributes: true,
        attributeFilter: ["style"]
      });
    }
  };

  handleProcessingChanged = () => debounce("ResultPage_handleProcessingChanged", 100, () => {
    if (window.processingManager.processing.site !== this.props.site) {
      this.props.history.replace(routes.INDEX);
      return;
    }

    const query = getLocationQueryObject();
    const isNewResult = this.state.file === null;
    const nextState = {
      isLoading: false,
      tabs: window.processingManager.processing.groups.filter((group) => group !== creativeGroups.CROP),
      file: window.processingManager.processing.file,
      creatives: mapNewCreativesToOld(window.processingManager.processing.creatives),
    };

    if (isNewResult) {
      let tab;

      if (nextState.tabs.indexOf(query.t) > -1) {
        tab = query.t;
      } else {
        tab = when(this.props.site, {
          "classic": nextState.tabs[0],
          "love": creativeGroups.CUPID21_2,
          "labs": nextState.tabs[0],
        });
      }

      nextState.defaultTab = tab;
      nextState.tab = tab;

      if (window.clientConfig.isWebview) {
        this.props.history.replace({
          pathname: generatePath(prefixRouteForSite(routes.RESULT, this.props.site)),
          search: `?t=${nextState.tab}`
        });
      }

      logEvent(userEvents.PAGE_RESULT, {
        file_id: nextState.file.id,
        group: tab,
        ref: query.ref || "unspecified",
      });

      this.hitProcessingFailedOnResultPageIsTriggered = false;
    }
    else {
      const groupCreatives = nextState.creatives.filter((c) => c.group === this.state.tab);
      const failedGroupCreative = groupCreatives.find(creativeUtils.creativeIsFailed);

      if (groupCreatives.length === 1 && failedGroupCreative) {

        logEvent(userEvents.TAB_FAILED, {
          group: this.state.tab,
          file_id: nextState.file.id,
          template_id: failedGroupCreative.template.id,
        });

        // const processedCreative = nextState.creatives.find(creativeUtils.creativeIsProcessed);
        // const processingCreative = nextState.creatives.find(creativeUtils.creativeIsProcessing);
        // if (processedCreative) {
        //   nextState.tab = processedCreative.group;
        // } else if (processingCreative) {
        //   nextState.tab = processingCreative.group;
        // } else {
        //   nextState.tab = creativeGroups.DISNEY;
        // }
      }
    }

    const failedCreatives = nextState.creatives.filter(creativeUtils.creativeIsFailed);
    if (failedCreatives.length > 0) {
      if (!this.hitProcessingFailedOnResultPageIsTriggered) {
        this.hitProcessingFailedOnResultPageIsTriggered = true;
        hitEvent(hits.PROCESSING_FAILED_ON_RESULT_PAGE);
      }
    }

    this.setState(nextState);
  });

  handleFileSelected = (file) => {
    hitEvent(hits.PHOTO_SELECT);
    logEvent(userEvents.PHOTO_SELECT, {page: "result"});

    this.props.history.push(prefixRouteForSite(routes.CREATE, this.props.site), {file});
  };

  handleImageLoaded = (creative) => {
    logEvent(userEvents.CREATIVE_VIEW, {
      ...getShareEventProps(this.state, creative),
      original_is_hide: clientStorage.getBeforePhotoShouldBeRemoved(),
    });
  };

  handleShare = (provider, sdCreative, hdCreative, props) => {
    props = props || {};

    switch (provider) {
      case SHARE_PROVIDER_SNAPCHAT: {
        hitEvent(hits.SHARE_CLICK_SNAPCHAT);
        break;
      }
      case SHARE_PROVIDER_FACEBOOK: {
        hitEvent(hits.SHARE_CLICK_FACEBOOK);
        break;
      }
      case SHARE_PROVIDER_INSTAGRAM: {
        hitEvent(hits.SHARE_CLICK_INSTAGRAM);
        break;
      }
      default:
        break;
    }

    logEvent(userEvents.SHARE, {
      provider,
      ...props,
      ...getShareEventProps(this.state, sdCreative, hdCreative),
      original_is_hide: clientStorage.getBeforePhotoShouldBeRemoved(),
    });
  };

  handleDownload = (creative, hdCreative) => {
    logEvent(userEvents.DOWNLOAD_START, {
      ...getShareEventProps(this.state, creative, hdCreative),
      original_is_hide: clientStorage.getBeforePhotoShouldBeRemoved(),
    });

    setTimeout(() => {
      if (window.clientConfig.isWebview) {
        webviewUtils.webviewShareDownload(hdCreative.file.url);
      } else {
        const url = new URL(window.appConfig.paths.api);
        url.pathname = "/api/download";
        url.searchParams.append("content_url", hdCreative.file.url);
        url.searchParams.append("group", creative.group);
        url.searchParams.append("creative_id", creative.id);

        window.location.href = url.toString();
      }
    }, 100);
  };

  showDownloadModal = (creative, withHD) => {
    this.context.pushModal(<DownloadModal
      key="ResultPage-DownloadModal"
      site={this.props.site}
      history={this.props.history}
      tab={this.state.tab}
      file={this.state.file}
      creative={creative}
      withHD={withHD}
      downloadHandler={this.handleDownload}
      shareHandler={this.handleShare}
    />);
  };

  handleDownloadButtonClick = (e, creative, withHD) => {
    logEvent(userEvents.DOWNLOAD, {
      ...getShareEventProps(this.state, creative),
      original_is_hide: clientStorage.getBeforePhotoShouldBeRemoved(),
    });

    hitEvent(hits.DOWNLOAD);

    this.showDownloadModal(creative, withHD);
  };

  handleRefreshButtonClick = (group) => debounce("ResultPage_handleRefreshButtonClick", 100, () => {
    logEvent(userEvents.TAB_REFRESH, {group});

    const groupCreatives = window.processingManager.processing.creatives.filter((creative) => creative.group === group);
    const groupConfigs = creativesConfigs.filter((config) => config.group === group);
    const unusedConfigs = groupConfigs.filter((config) => !groupCreatives.find((creative) => creative.templateId === config.templateId));

    const currentSelectedCreative = window.processingManager.processing.getSelectedCreativeInGroup(group);
    currentSelectedCreative.setAsSelected(false);

    if (unusedConfigs.length > 0) {
      const nextConfig = unusedConfigs.random();

      window.processingManager.processing.addCreative(new Creative()
        .configureByConfig(nextConfig)
        .setAsSelected(true)
        .setAsRefreshed(true));

      window.processingManager.update();
    } else {
      const currentIndex = groupCreatives.findIndex((creative) => creative.id === currentSelectedCreative.id);
      const nextCreative = groupCreatives.find((_, index) => index > currentIndex);
      if (nextCreative) {
        nextCreative.setAsSelected(true);
      } else {
        groupCreatives[0].setAsSelected(true);
      }

      this.handleProcessingChanged();
    }

    this.refreshCollage();
  });

  handleRetryButtonClick = (creative) => debounce("ResultPage_handleRetryButtonClick", 100, () => {
    const config = creativesConfigs.find((config) => config.templateId === creative.template.id);

    window.processingManager.processing.removeCreative(creative);

    window.processingManager.processing.addCreative(new Creative()
        .configureByConfig(config)
        .setAsSelected(true));

    window.processingManager.update();
  });

  handleVectorRefreshButtonClick = (templateId) => debounce("ResultPage_handleVectorRefreshButtonClick", 100, () => {
    logEvent(userEvents.TAB_REFRESH, {group: "vector", template_id: templateId});

    const groupCreatives = window.processingManager.processing.creatives
      .filter((creative) => creative.group === creativeGroups.VECTOR);

    const selectedCreative = window.processingManager.processing.getSelectedCreativeInGroup(creativeGroups.VECTOR);
    selectedCreative.setAsSelected(false);

    const creativeWithSameTemplateId = groupCreatives.find((creative) => creative.templateId === templateId);
    if (creativeWithSameTemplateId) {
      creativeWithSameTemplateId.setAsSelected(true);
      this.handleProcessingChanged();
    } else {
      const creativeConfig = new CreativeConfig(selectedCreative.group, templateId, selectedCreative.handler);
      const creative = new Creative()
        .configureByConfig(creativeConfig)
        .setAsSelected(true)
        .setAsRefreshed(true);

      window.processingManager.processing.addCreative(creative);
      window.processingManager.update();
    }

    this.refreshCollage();
  });

  handleRefreshCollageButtonClick = (group) => debounce("ResultPage_handleRefreshCollageButtonClick", 100, () => {
    logEvent(userEvents.TAB_REFRESH, {group});

    const processing = window.processingManager.processing;
    const selectedCollage = processing.getSelectedCreativeInGroup(creativeGroups.COLLAGE);
    if (!selectedCollage) {
      return;
    }

    const collagesTypes = processing.getExtra(Processing.EXTRA_COLLAGES_TYPES);
    if (!collagesTypes || collagesTypes.length < 2) {
      return;
    }

    const currentTypeIndex = collagesTypes.findIndex((type) => type === selectedCollage.alias);
    const nextType = (currentTypeIndex > -1 && currentTypeIndex + 1 < collagesTypes.length)
      ? collagesTypes[currentTypeIndex + 1]
      : collagesTypes[0];

    selectedCollage.setAsSelected(false);

    const collageConfig = new CreativeConfig(creativeGroups.COLLAGE, selectedCollage.templateId, selectedCollage.handler);
    const collageCreative = new Creative()
      .configureByConfig(collageConfig)
      .setAsSelected(true)
      .setAsRefreshed(true);

    collageCreative.setAlias(nextType);
    collageCreative.setExtra(Creative.EXTRA_COLLAGE_GROUPS, collageGroupCreatives[nextType]);

    window.processingManager.processing.addCreative(collageCreative);
    window.processingManager.update();
  });

  refreshCollage = () => {
    const selectedCollage = window.processingManager.processing.getSelectedCreativeInGroup(creativeGroups.COLLAGE);
    if (!selectedCollage) {
      return;
    }

    const selectedCollageHash = selectedCollage.getExtra(Creative.EXTRA_COLLAGE_HASH);
    const parsedSelectedCollageHash = selectedCollageHash.split(";").map((cs) => cs.split(":"));

    const collageChildCount = parsedSelectedCollageHash.filterLength((cd) => {
      const gcs = window.processingManager.processing.getSelectedCreativeInGroup(cd[0]);
      return gcs.templateId === parseInt(cd[1]);
    });

    if (parsedSelectedCollageHash.length === collageChildCount) {
      return;
    }

    selectedCollage.setAsSelected(false);

    const collageConfig = new CreativeConfig(creativeGroups.COLLAGE, selectedCollage.templateId, selectedCollage.handler);
    const collageCreative = new Creative()
      .configureByConfig(collageConfig)
      .setAsSelected(true);

    collageCreative.setAlias(selectedCollage.alias);
    collageCreative.setExtra(Creative.EXTRA_COLLAGE_GROUPS, selectedCollage.getExtra(Creative.EXTRA_COLLAGE_GROUPS));

    window.processingManager.processing.addCreative(collageCreative);
    window.processingManager.update();
  };

  handleBodyRefreshTemplateSelect = (group, type, data) => {
    console.log(type, data);

    /** @type {Creative} */
    const selectedCreative = window.processingManager.processing
      .getSelectedCreativeInGroup(group);

    selectedCreative.setAsSelected(false);

    let nextCreative;

    if (type === "body") {
      // меняем Creative.BODY_TEMPLATE_ID
      // переносим HeadTask, GenderTask
      nextCreative = new Creative()
        .configureByConfig(new CreativeConfig(
          selectedCreative.group,
          selectedCreative.templateId,
          selectedCreative.handler
        ))
        .setExtra(Creative.EXTRA_BODY_TEMPLATE_ID, data.templateId)
        .setTask("gender", selectedCreative.getTask("gender"))
        .setTask("head", selectedCreative.getTask("head"))
        .setTask("body", data.taskResult)
        .setAsSelected(true)
        .setAsRefreshed(true);
    } else {
      // меняем входной templateId
      // переносим Creative.BODY_TEMPLATE_ID и GenderTask
      nextCreative = new Creative()
        .configureByConfig(new CreativeConfig(
          selectedCreative.group,
          data.templateId,
          selectedCreative.handler
        ))
        .setExtra(Creative.EXTRA_BODY_TEMPLATE_ID, selectedCreative.getExtra(Creative.EXTRA_BODY_TEMPLATE_ID))
        .setTask("gender", selectedCreative.getTask("gender"))
        .setAsSelected(true)
        .setAsRefreshed(true);
    }

    window.processingManager.processing.addCreative(nextCreative);
    window.processingManager.update();
  };

  showRemoveLogoModal = () => {
    this.context.pushModal(<RemoveLogoModal
      key="ResultPage-RemoveLogoModal"
      onShowButtonClick={this.handleShowWatermak}
      onHideButtonClick={this.handleHideWatermak}
    />);
  };

  handleToggleWatermak = () => debounce("ResultPage_handleToggleWatermak", 100, () => {
    clientStorage.setProWatermarkTooltipIsHidden(true);
    this.showRemoveLogoModal();
  });

  handleHideBeforePhotoButtonClick = () => {
    clientStorage.setBeforePhotoShouldBeRemoved(true);
    this.setState({isLoading: true}, this.handleProcessingChanged);
  };

  handleShowBeforePhotoButtonClick = () => {
    clientStorage.setBeforePhotoShouldBeRemoved(false);
    this.setState({isLoading: true}, this.handleProcessingChanged);
  };

  handleLabsButtonClick = () => debounce("ResultPage_handleLabsButtonClick", 100, () => {
    this.props.history.push(generatePath(prefixRouteForSite(routes.PROCESSING, "labs"), {id: this.state.file.id}));
  });

  handleLoveButtonClick = () => debounce("ResultPage_handleLoveButtonClick", 100, () => {
    this.props.history.push(generatePath(prefixRouteForSite(routes.PROCESSING, "love"), {id: this.state.file.id}));
  });

  handleClassicButtonClick = () => debounce("ResultPage_handleClassicButtonClick", 100, () => {
    if (this.props.site === "love" && window.clientConfig.isWeb) {
      window.location.href = window.appConfig.paths.app;
    } else {
      this.props.history.push(generatePath(prefixRouteForSite(routes.PROCESSING, "classic"), {id: this.state.file.id}));
    }
  });

  handleUniteButtonClick = (e) => {
    if (window.clientConfig.isWebview) {
      e.preventDefault();
      webviewOpenBrowser(UNITE_COM_TAB_LINK + "&utm_content=webview");
    }
  };

  handleShowWatermak = () => {
    clientStorage.setProWatermarkShouldBeRemoved(false);
    this.handleProcessingChanged();
  };

  handleHideWatermak = () => {
    clientStorage.setProWatermarkRemoveChoiceAt(Date.now());
    clientStorage.setProWatermarkShouldBeRemoved(true);
    this.handleProcessingChanged();
  };

  handleTabClick = (group) => {
    if (this.state.tab === group) {
      return;
    }

    if (window.clientConfig.isWebview) {
      this.props.history.replace({
        pathname: generatePath(prefixRouteForSite(routes.RESULT, this.props.site)),
        search: `?t=${group}`
      });
    }

    const creativesInGroup = this.state.creatives.filter((creative) => creative.group === group);

    logEvent(userEvents.TAB_SELECT, {
      group,
      group_is_empty: creativesInGroup.length === 0,
      prev_group: this.state.tab,
    });

    this.setState({tab: group});
  };

  renderTab = (group) => {
    const tabClassNames = ["collage__tab_" + group];
    if (this.state.tab === group) {
      tabClassNames.push("active-tab");
    }

    const creative = this.state.creatives.find((creative) => creative.group === group && creative.is_selected);
    let preview = undefined;
    if (creative && creativeUtils.creativeIsProcessed(creative)) {
      preview = <img src={creative.file.url} alt="Preview" />;
    }

    if (group === creativeGroups.CARTOON_ANIM) {
      preview = undefined;
    }

    const newLabel = [
      creativeGroups.CARTOON_VECTOR_BODY,
      creativeGroups.VOILA,
    ].indexOf(group) > -1
      ? <span className="new-label-upd">new</span>
      : undefined;

    return <button
      key={group}
      className={tabClassNames.join(" ")}
      onClick={() => this.handleTabClick(group)}>
      {newLabel}
      {preview}
    </button>;
  };

  renderTabContainer = (commonTabSettings) => {
    commonTabSettings.group = this.state.tab;
    commonTabSettings.withHD = [
      creativeGroups.DISNEY,
      creativeGroups.DISNEY_2D,
      creativeGroups.ARCHER,
      creativeGroups.CARICATURE_3,
    ].indexOf(commonTabSettings.group) > -1;

    if (commonTabSettings.group === creativeGroups.COLLAGE) {
      const collageTypes = window.processingManager.processing.getExtra(Processing.EXTRA_COLLAGES_TYPES);
      commonTabSettings.canBeRefreshed = collageTypes && collageTypes.length > 1;
    } else {
      commonTabSettings.canBeRefreshed = creativesConfigs
        .filter((config) => config.group === commonTabSettings.group).length > 1;
    }

    switch (this.state.tab) {
      case creativeGroups.VECTOR:
        return this.renderVectorTab(commonTabSettings);
      case creativeGroups.COLLAGE:
        return this.renderSimpleTab({...commonTabSettings, onRefreshButtonClick: this.handleRefreshCollageButtonClick});
      case creativeGroups.CARTOON_VECTOR_BODY:
        return this.renderBodyTab(commonTabSettings);
      case "labs":
        return this.renderLabsTab({});
      case "unite":
        return this.renderUniteTab({});
      case "classic":
        return this.renderClassicTab({});
      case "love":
        return this.renderLoveTab({});
      default: {
        return this.renderSimpleTab(commonTabSettings);
      }
    }
  };

  renderSimpleTab = (props) => <SimpleTab {...props} />;
  renderVectorTab = (props) => <VectorTab {...props} onTemplateSelect={this.handleVectorRefreshButtonClick} />;
  renderBodyTab = (props) => <BodyTab {...props} onTemplateSelect={this.handleBodyRefreshTemplateSelect} />;
  renderLabsTab = (props) => <LabsTab {...props} onButtonClick={this.handleLabsButtonClick} />;
  renderLoveTab = (props) => <LoveTab {...props} onButtonClick={this.handleLoveButtonClick} />;
  renderClassicTab = (props) => <ClassicTab {...props} onButtonClick={this.handleClassicButtonClick} />;
  renderUniteTab = (props) => <UniteTab {...props} onButtonClick={this.handleUniteButtonClick} />;

  getViewParams = () => {
    const commonTabSettings = {
      file: this.state.file,
      creatives: this.state.creatives,
      onImageLoaded: this.handleImageLoaded,
      onDownloadButtonClick: (e, creative, withHD) => this.handleDownloadButtonClick(e, creative, withHD),
      onRefreshButtonClick: this.handleRefreshButtonClick,
      onShowWatermarkButtonClick: this.handleShowWatermak,
      onHideWatermarkButtonClick: this.handleToggleWatermak,
      onHideBeforePhotoButtonClick: this.handleHideBeforePhotoButtonClick,
      onShowBeforePhotoButtonClick: this.handleShowBeforePhotoButtonClick,
      onRetryButtonClick: this.handleRetryButtonClick,
      canBeRefreshed: true,
      onFileSelected: this.handleFileSelected,
      site: this.props.site,
    };

    const showTabs = this.state.tabs.slice();

    // if (this.props.site === "labs") {
    //   showTabs.splice(0, 0, "classic");
    // } else if (this.props.site === "classic") {
    //   showTabs.push("unite");
    //   showTabs.push("labs");
    // }

    return {commonTabSettings, showTabs};
  };

  renderView = ({commonTabSettings, showTabs}) => {
    return <main className={"collage-page" + (this.props.site === "labs" ? " labs-container" : "")}>
      <div className="container">
        <HomeButton page={"result"} site={this.props.site} />
      </div>
      <div className="collage-page-content container">
        <div className="tabs-container">
          {showTabs.map((group) => this.renderTab(group))}
        </div>
        {this.renderTabContainer(commonTabSettings)}
      </div>
    </main>;
  };

  render() {
    if (this.state.isLoading) {
      const file = this.props.location.state
        && this.props.location.state.file
        && this.props.location.state.file.url;

      return (window.appConfig.designVersion === "v2" || this.props.site === "love")
        ? <LoadingV2 site={this.props.site} />
        : <Loading image={file} site={this.props.site} />;
    }

    const params = this.getViewParams();

    return this.renderView(params);
  }
}

ResultPage.contextType = AppContext;

export function getShareEventProps(state, creative, hdCreative) {
  const props = {
    file_id: state.file.id,
    template_id: creative.template.id,
    group: state.tab,
    default_tab_group: state.defaultTab,
    alias: creative.alias,
    is_refresh: creative._.isRefreshed,
  };

  if (hdCreative) {
    props.sr_template_id = hdCreative.template.id;
  }

  if (creative._.hasExtra(Creative.EXTRA_BODY_TEMPLATE_ID)) {
    props.body_template_id = creative._.getExtra(Creative.EXTRA_BODY_TEMPLATE_ID);
  }

  return props;
}