module.exports = {
  "strings": {
    "try_another_photo": "다른 사진으로 만들기",

    "landing__title_part_1": "우 후!",
    "landing__title_part_2": "수없이 꿈꿔왔던 만화 같은 삶을 살 시간입니다.",
    "landing__subtitle": "AI에게 당신의 사진을 주세요. 😜",
    "landing__select_photo_button": "사진 선택",
    "landing__get_the_app_button": "앱 받기",
    "loading__uploading": "불러오는 중...",

    "landing__title_labs": "ToonMe.com LABS",
    "landing__subtitle_labs": "귀하는 지금 특별한 실험적 영역에 있습니다. 아래의 기본 버전은 원래 스타일 다시 전환해줍니다.",
    "landing__labs_back_button": "ToonMe Classic으로 돌아가기",
    "landing__labs_choice": "또는 <span>ToonMe&nbsp;LABS</span>의 실험적인 스타일을 시도해 보세요",

    "result__labs_tab_title": "Welcome to ToonMe LABS",
    "result__labs_tab_message": "저희가 실험하고 있는 새로운 스타일을 살짝 엿볼 수 있습니다! 언제든지 원래 스타일로 다시 전환할 수 있습니다.",
    "result__labs_tab_button": "시도해보세요!",
    "result__tab_classic_title": "실험적 효과를 즐기고 계신가요?",
    "result__tab_classic_info": "여기에 머물거나 ToonMe의 기본 버전으로 다시 전환하세요.",
    "result__tab_classic_btn": "ToonMe Classic으로 돌아가기",

    "processing_text_1": "피부를 노랗게 변경하는 중입니다... 🖌️",
    "processing_text_2": "얼굴 특징을 만화로 변경하는 중입니다...",
    "processing_text_3": "디즈니의 공주처럼 보이네요. 농담인가요. 아닌가요?.. 🙈",
    "processing_text_4": "이곳에서 꿈이 만화로 실현됩니다. 🦄",
    "processing_text_5": "사진을 미세 조정하는 중입니다!",
    "processing_text_6": "만화로 만들기가 진행 중입니다!",
    "processing_text_7": "월트 디즈니도 이것을 좋아했을 것입니다! ✨",

    "collage__select_photo": "당신의 사진으로 시도하세요.",
    "collage__download": "HD로 다운로드하기",
    "collage__refresh": "새로고침",
    "collage__more_styles": "더 많은 스타일",

    "download_modal__title": "여기서 멈추지 마세요!",
    "download_modal__message": "툰 스타가 된 기분이 어떠세요? 가장 좋아하는 결과를 게시하여 Instagram에서 유명해진 순간을 공유하세요!",
    "download_modal__message_wait": "HD로 결과를 생성하는 중입니다…",
    "download_modal__message_ready": "HD 버전 완료, 다운로드가 시작되었습니다…",
    "download_modal__dismiss_button": "확인",
    "download_modal__download_button": "다시 다운로드하기",

    "error__error": "오류",

    "internal_error": "오류가 발생했습니다...",
    "error_overload_webview": "대단히 죄송합니다. 앱 다운로드 수가 많아 서버 부하가 높습니다. 대신 피드 탭을 사용하시거나 잠시 후에 다시 확인해주세요. 모든 것을 빠르게 해결하기 위해 노력하고 있습니다.",
    "error_overload_web": "대단히 죄송합니다. 앱 다운로드 수가 많아 서버 부하가 높습니다. 잠시 후에 다시 확인해주세요. 모든 것을 빠르게 해결하기 위해 노력하고 있습니다.",

    "rateapp_dialog__title": "ToonMe가 마음에 드세요?",
    "rateapp_dialog__message-bold": "앱에 별을 주어 다른 사용자도 이 앱을 발견하고 좋아할 수 있게 도와주세요!",
    "rateapp_dialog__message": "추신. ToonMe가 입소문을 타면서 서버 문제로 인해 영향을 받는 등급 향상에도 도움이 될 것입니다. 🙏",
    "rateapp_dialog__rate_button": "평가하기!",
    "rateapp_dialog__skip_button": "건너뛰기",

    "btn_store__title":"<span class=\"btn-store-title\"><span class=\"btn-store-name\">{{market}}</span>에서</span><span class=\"btn-store-title\">다운로드</span>",
    "btn_store__ios":"App Store",
    "btn_store__android":"Google Play",
    "btn_cancel": "취소 및 표준 화질로 저장",
    "btn_clone": "Beware of cheap clone!",
    "btn_contacts_form": "연락처",
    "btn_back": "back",

    "vector_tab_refresh__title": "거의 다 되었습니다!",
    "vector_tab_refresh__subtitle": "초상화 선택",

    "updated_at": "{{date}}에 새로운 효과로 업데이트 됨",

    "warning_clones": "경고!",
    "warning_clones_link": "저렴한 복제품에 주의하세요!",

    "snap_dialog__title": "전-후 또는 바로 TOON하실래요?",
    "snap_dialog__message": "스냅 방법을 선택하세요!",
    "snap_dialog__share_button": "공유",
    "snap_dialog__back_button": "뒤로가기",

    "title_contacts_form": "2억 6천만 이상이 다운로드한 세계 최고의 iOS 및 Android 포토그라피 앱 개발자로부터…",

    "toggle_watermark_tooltip__text": "로고를 누르면 없어집니다",
    "toggle_watermark_on": "로고 온/오프",
    "toggle_watermark_off": "로고 온/오프",

    "remove_logo_modal__title": "유지할까, 말까...",
    "remove_logo_modal__message": "헤이, 이 로고가 이 효과의 디자인에 포함되어 있다는 것을 알고 있었나요? 이 로고를 유지하면 우리는 행복할 거예요.",
    "remove_logo_modal__btn_cancel": "로고 유지하기",
    "remove_logo_modal__btn_remove": "어쨌든 제거하기",

    // v2
    "landing__toonme_on_instagram": "#ToonMe 인스타그램",

    "creative_failed_message": "죄송합니다. 이 TOON에 문제가 발생했습니다. 🤔 다른 스타일을 선택하거나 다시 시도하세요.",
    "creative_failed_retry_btn": "다시 시도하기",

    "refresh_body__btn_body": "바디 스타일 선택",
    "refresh_body__btn_head": "머리 스타일 선택",
    "button_choice_gender_male": "남성",
    "button_choice_gender_female": "여성",
    "button_choice_gender_text": "스타일",
  },
};
