import React from "react";

export default class ProcessingAdSlot extends React.Component {

  componentDidMount() {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }

  render() {
    return <ins
      className="adsbygoogle"
      style={{display: "block"}}
      data-ad-client="ca-pub-8698123149353813"
      data-ad-slot="1528007732"
      data-ad-format="auto"
      data-full-width-responsive="true" />;
  }
}