import React from "react";
import BodyTabDefault from "../result/BodyTab";
import SimpleTab from "./SimpleTab";
import i18n from "../../i18n";

export default class BodyTab extends BodyTabDefault {

  renderResult = () => {
    const tabProps = {...this.props};
    tabProps.onRefreshButtonClick = this.handleRefreshButtonClick;
    tabProps.canBeRefreshed = true;

    return <SimpleTab {...tabProps} />;
  };

  renderHeadChooser = () => {
    if (!this.state.headTask || this.state.headTask.status.toLowerCase() === "inprogress") {
      return <div className="creative-holder">
        <div className="creative-holder-placeholder" />
        <div className="loader-roller-wrapper">
          <div className="loader-roller loader-roller1">
            <div className="roller" />
            <div className="roller" />
          </div>
          <div className="loader-roller loader-roller2">
            <div className="roller" />
            <div className="roller" />
          </div>
          <div className="loader-roller loader-roller3">
            <div className="roller" />
            <div className="roller" />
          </div>
        </div>
      </div>;
    }

    if (this.state.headTask.status.toLowerCase() === "ok") {
      return <div className="choose-page">
        <div className="container">
          <h2>{i18n.t("vector_tab_refresh__title")}</h2>
          <p>{i18n.t("vector_tab_refresh__subtitle")}</p>
        </div>
        <div className="choose-items">
          {this.state.headTask.results.map((resultItem) => <div className="choose-item-container" key={resultItem.templateId}>
            <div className="choose-item">
              <img
                src={resultItem.resultUrl}
                alt={resultItem.templateId}
                onClick={() => this.handleHeadSelectTemplate(resultItem.templateId)} />
            </div>
          </div>)}
        </div>
      </div>;
    }

    return <div>task failed</div>;
  };
}
