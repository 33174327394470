import clientStorage from "./client-storage";
import Creative from "../photolab/Creative";

const STATUS_FAILED = -1;
const STATUS_PENDING = 0;
const STATUS_PROCESSED = 1;

export function creativeIsProcessed(creative) {
  return creative.status === STATUS_PROCESSED;
}

export function creativeIsFailed(creative) {
  return creative.status === STATUS_FAILED;
}

export function creativeIsProcessing(creative) {
  return creative.status === STATUS_PENDING;
}

export function mapNewCreativesToOld(newCreatives) {
  return newCreatives.map(mapCreativeToOld);
}

/**
 * @param {Creative} creative
 * @returns {Object}
 */
export function mapCreativeToOld(creative) {
  const resultTask = creative.getTask("store");
  const file = {
    url: creative.resultUrl || "",
    width: (resultTask && resultTask.result && resultTask.result.width) || 0,
    height: (resultTask && resultTask.result && resultTask.result.height) || 0,
    extension: (resultTask && resultTask.result && resultTask.result.extension) || undefined,
  };

  if (clientStorage.getProWatermarkShouldBeRemoved()) {
    file.url = creative.getFile("before_watermark") || file.url;
  }

  if (clientStorage.getBeforePhotoShouldBeRemoved()) {
    file.url = creative.getFile("template") || file.url;
  }

  return {
    extra: "",
    file: file,
    group: creative.group,
    alias: creative.alias,
    id: creative.id,
    is_selected: creative.isSelected,
    status: creative.data.status,
    tasks: creative.data.tasks,
    template: {
      id: creative.templateId,
      id_hd: creative.getExtra(Creative.EXTRA_HD_TEMPLATE_ID) || 0,
    },
    _: creative,
  }
}