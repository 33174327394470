export default class PhotolabTaskBuilder {

  _language = "en";
  _methods = [];
  _images = [];

  setLanguage = (language) => {
    this._language = language;

    return this;
  };

  addMethod = (method, index) => {
    this._methods.push({
      item: method,
      index: index || (this._methods.length + 1),
    });

    return this;
  };

  addImage = (imageUrl, index) => {
    this._images.push({
      item: imageUrl,
      index: index || (this._images.length + 1),
    });

    return this;
  };

  build = () => {
    const doc = document.implementation.createDocument("", "", null);
    const rootElement = doc.createElement("image_process_call");

    const langElement = doc.createElement("lang");
    langElement.innerHTML = this._language;
    rootElement.appendChild(langElement);

    const methodsListElement = doc.createElement("methods_list");

    this._methods.forEach((item) => {
      const methodElement = doc.createElement("method");
      methodElement.setAttribute("order", item.index);

      const nameElement = doc.createElement("name");
      nameElement.innerHTML = item.item.name;

      const paramsElement = doc.createElement("params");
      paramsElement.innerHTML = Object.keys(item.item.params).map((k) => `${k}=${item.item.params[k]}`).join(";");

      methodElement.appendChild(nameElement);
      methodElement.appendChild(paramsElement);

      methodsListElement.appendChild(methodElement);
    });

    rootElement.appendChild(methodsListElement);

    this._images.forEach((item) => {
      const imageUrlElement = doc.createElement("image_url");
      imageUrlElement.setAttribute("order", item.index);
      imageUrlElement.innerHTML = item.item.url;

      rootElement.appendChild(imageUrlElement);
    });

    const abortMethodsChainOnErrorElement = doc.createElement("abort_methods_chain_on_error");
    abortMethodsChainOnErrorElement.innerHTML = "1";
    rootElement.appendChild(abortMethodsChainOnErrorElement);

    const abortAttemptsOnErrorElement = doc.createElement("abort_attempts_on_error");
    abortAttemptsOnErrorElement.innerHTML = "1";
    rootElement.appendChild(abortAttemptsOnErrorElement);


    doc.appendChild(rootElement);

    return new XMLSerializer().serializeToString(doc);
  };
}