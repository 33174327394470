module.exports = {
  "strings": {
    "try_another_photo": "Загрузить другое фото",

    "landing__title_part_1": "Ура!",
    "landing__title_part_2": "Самое время превратиться в любимых <span>мультяшных</span> персонажей.",
    "landing__subtitle": "Искусственный интеллект ждёт фото 😜",
    "landing__select_photo_button": "Выберите фото",
    "landing__get_the_app_button": "Скачать приложение",
    "loading__uploading": "Загрузка...",

    "landing__title_labs": "ToonMe.com LABS",
    "landing__subtitle_labs": "Добро пожаловать в экспериментальную лабораторию! Чтобы переключиться на стандартные стили, нажмите на кнопку внизу.",
    "landing__labs_back_button": "Назад на ToonMe Classic",
    "landing__labs_choice": "Или поиграйте с экспериментальными стилями на <span>ToonMe&nbsp;LABS</span>",

    "result__labs_tab_title": "Welcome to ToonMe LABS",
    "result__labs_tab_message": "Мы экспериментируем с новыми стилями и приглашаем вас принять участие! Вы можете переключиться на стандартные стили в любой момент ;)",
    "result__labs_tab_button": "Начнём",
    "result__tab_classic_title": "Нравится экспериментировать?",
    "result__tab_classic_info": "Оставайтесь здесь или переключитесь на основную версию ToonMe",
    "result__tab_classic_btn": "Назад на ToonMe Classic",

    "processing_text_1": "Кожу сделаем жёлтой... 🖌️",
    "processing_text_2": "Черты лица будут мультяшными...",
    "processing_text_3": "Вы похожи на диснеевскую принцессу! Шутка. 🤣 Или нет? 🙈",
    "processing_text_4": "Это место, где мечты омультяшиваются! 🦄",
    "processing_text_5": "Добавим немного красок вашему фото...",
    "processing_text_6": "Не входить! Идёт омультяшивание...",
    "processing_text_7": "Уолту Диснею точно бы понравилось! ✨",

    "collage__select_photo": "Попробовать со своим фото",
    "collage__download": "Скачать в HD качестве",
    "collage__refresh": "Обновить",
    "collage__refresh_more": "Ещё",
    "collage__more_styles": "Больше стилей",

    "download_modal__title": "Это ещё не всё!",
    "download_modal__message": "Каково это быть мульти-звездой? Разделите свой момент славы с друзьями, поделившись лучшим результатом в Instagram!",
    "download_modal__message_wait": "Создаём результат в HD качестве...",
    "download_modal__message_ready": "HD версия готова, начинаем загрузку...",
    "download_modal__dismiss_button": "Понятно",
    "download_modal__download_button": "Скачать ещё раз",

    "error__error": "Ошибка",

    "internal_error": "Произошла ошибка...",
    "error_overload_webview": "Нам очень жаль, но у нас возрасли нагрузки на сервера в связи с большим количеством новых установок приложения. Пожалуйста, перейдите в Ленту или повторите попытку позже. Мы постараемся починить всё как можно скорее.",
    "error_overload_web": "Нам очень жаль, но у нас возрасли нагрузки на сервера в связи с большим количеством новых установок приложения. Пожалуйста, повторите попытку позже. Мы постараемся починить всё как можно скорее.",

    "rateapp_dialog__title": "Любите ToonMe?",
    "rateapp_dialog__message-bold": "Оцените приложение, чтобы другие пользователи смогли найти его и полюбить!",
    "rateapp_dialog__message": "P.S. ваша оценка поможет нам восстановить рейтинг, упавший из-за проблем с серверами на фоне популряности ToonMe 🙏",
    "rateapp_dialog__rate_button": "Оценить!",
    "rateapp_dialog__skip_button": "Закрыть",

    "btn_store__title":"<span class=\"btn-store-title\">Скачать на</span><span class=\"btn-store-name\">{{market}}</span>",
    "btn_store__ios":"App Store",
    "btn_store__android":"Google Play",
    "btn_cancel": "Закрыть и сохранить<br />в обычном качестве",
    "btn_clone": "Beware of cheap clone!",
    "btn_contacts_form": "Напишите нам",
    "btn_back": "back",

    "vector_tab_refresh__title": "Почти готово",
    "vector_tab_refresh__subtitle": "Выберите портрет",

    "updated_at": "Новые эффекты добавлены <span class=\"updated-at-date\">{{date}}</span>",

    "warning_clones": "ВНИМАНИЕ!",
    "warning_clones_link": "ИЗБЕГАЙТЕ НЕДОБРОСОВЕСТНЫХ КЛОНОВ!",

    "snap_dialog__title": "До и после или просто рисунок?",
    "snap_dialog__message": "Выберите, как опубликовать!",
    "snap_dialog__share_button": "Поделиться",
    "snap_dialog__back_button": "Назад",

    "title_contacts_form": "Разработано командой приложения Photo Lab, мирового лидера по обработке фотографий на платформах iOS и Android с количеством установок в 260 млн. И это не предел...",

    "toggle_watermark_tooltip__text": "Коснитесь лого, чтобы убрать его",
    "toggle_watermark_on": "С лого",
    "toggle_watermark_off": "Без лого",

    "remove_logo_modal__title": "С или без... Вот в чём вопрос",
    "remove_logo_modal__message": "А вы знали, что логотип является частью дизайна этого эффекта? Мы будем рады, если вы его оставите.",
    "remove_logo_modal__btn_cancel": "Оставить лого",
    "remove_logo_modal__btn_remove": "Убрать лого",

    // v2
    "landing__toonme_on_instagram": "#ToonMe в Instagram",

    "creative_failed_message": "Ой, с этим стилем что-то пошло не так. 🤔 Выберите другой или попробуйте позже.",
    "creative_failed_retry_btn": "Попробовать ещё раз",

    "refresh_body__btn_body": "Выбрать стиль тела",
    "refresh_body__btn_head": "Выберать стиль портрета",
    "button_choice_gender_male": "мужские",
    "button_choice_gender_female": "женские",
    "button_choice_gender_text": "стили",
  },
};
