import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import {Route, Switch, withRouter} from "react-router";
import {BrowserRouter} from "react-router-dom";
import routes from "./routes";
import IndexPageDefault from "./pages/IndexPage";
import IndexPageV2 from "./pages/IndexPageV2";
import ErrorPage from "./pages/ErrorPage";
import CreatePage from "./pages/CreatePage";
import ResultPageDefault from "./pages/result/ResultPage";
import ResultPageV2 from "./pages/result_v2/ResultPageV2";
import ResultPageV3 from "./pages/result_v3/ResultPageV3";
import OfficialPage from "./pages/OfficialPage";
import InstagramPage from "./pages/InstagramPage";
import "./styles/app.scss";
import ModalWrapper from "./components/ModalWrapper";
import {AppContextProvider} from "./contexts/AppContext";
import AppContext from "./contexts/AppContext";
import {hitEvent, hits, logEvent, userEvents} from "./utils/log";
import * as webviewUtils from "./utils/webview";
import ProcessingPage from "./pages/ProcessingPage";
import TemplatesPreviewPage from "./pages/TemplatesPreviewPage";
import {isLoveSiteByLocation} from "./utils/etc";
import PrivacyPage from "./pages/PrivacyPage";
import LoadingV2 from "./components/LoadingV2";
import Loading from "./components/Loading";
import HiringPage from "./pages/HiringPage";
import FeedBannerPage from "./pages/FeedBannerPage";

if (window.appConfig.sentry.isEnabled) {
  Sentry.init({
    environment: process.env.NODE_ENV,
    dsn: window.appConfig.sentry.dsn,
  });
  Sentry.setUser({id: window.clientConfig.token});
  Sentry.setTag("client_type", window.clientConfig.isWebview ? "webview" : "web");
}

class App extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
    };
  }

  componentDidMount() {
    if (window.clientConfig.isWebviewMock) {
      hitEvent(hits.WEBVIEW_NOT_RECOGNIZED);
      logEvent(userEvents.CDN_HOST_WITHOUT_GET_PARAMS);
    }

    this.checkJsonSuspended();
    this.updateBodyClasses();

    window.webviewHolders.tabSelected.subscribe((v) => {
      if (v > 0) {
        hitEvent(hits.WEBVIEW_TAB_FOCUS);
        logEvent(userEvents.WEBVIEW_TAB_FOCUS, {
          os: window.clientConfig.isWebviewAndroid
            ? "android"
            : (window.clientConfig.isWebviewIOS ? "ios" : "other")
        });
      }
    });

    if (window.clientConfig.isWebview) {
      webviewUtils.webviewCheckInstalledApps((apps) => {
        this.context.setInstalledApps(apps);
      })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged()
    }
  }

  checkJsonSuspended = () => {
    if (window.clientConfig.isWeb) {
      this.setState({isLoading: false});
      return;
    }

    window.axios.get("/webview-suspended.json?r=" + Math.random()).then((res) => {
      const clientId = window.clientConfig.webviewParams["aid"]
        || window.clientConfig.webviewParams["vicman_unified_id"];

      const letterIsMatch = res.data.letters
        && res.data.letters.indexOf(clientId.substring(0, 1).toUpperCase()) >= 0;

      const countryIsMatch = res.data.countries
        && res.data.countries.indexOf((window.clientConfig.webviewParams["country"] || "").toUpperCase()) >= 0;

      if (countryIsMatch && letterIsMatch) {
        const redirectUrl = new URL(res.data.url[window.clientConfig.isWebviewIOS ? "ios" : "android"]);
        redirectUrl.searchParams.append("retry", window.location.hostname);

        Object.keys(window.clientConfig.webviewParams).forEach((key) => {
          redirectUrl.searchParams.append(key, window.clientConfig.webviewParams[key]);
        });

        window.location.href = redirectUrl.toString();
      } else {
        this.setState({isLoading: false});
      }
    }).catch((err) => {
      console.error(err);
      this.setState({isLoading: false});
    });

    // fallback timeout
    setTimeout(() => this.setState({isLoading: false}), 1000);
  };

  updateBodyClasses = () => {
    document.body.classList.add("app-locale--" + window.clientConfig.locale);
    document.body.classList.add("app-locale--" + window.clientConfig.lang);
    document.body.classList.add("app-type--" + (window.clientConfig.isWebview ? "webview" : "web"));

    if (window.appConfig.webAds.isEnabled) {
      document.body.classList.add("app-webads-enabled");
    }

    toggleBodyClass("app-webview--ios", window.clientConfig.isWebviewIOS);
    toggleBodyClass("app-webview--android", window.clientConfig.isWebviewAndroid);

    const isLoveSite = isLoveSiteByLocation();

    if (isLoveSite) {
      document.body.classList.add("app-design-v3");
      document.body.classList.remove("app-design-v1", "app-design-v2");
    }
    else if (window.location.pathname === "/labs"
      || window.location.pathname === "/labs/"
      || window.location.pathname.indexOf("/labs/result") === 0
    ) {
      document.body.classList.add("app-design-v1");
      document.body.classList.remove("app-design-v2", "app-design-v3");
    }
    else if (window.appConfig.designVersion === "v2") {
      document.body.classList.add("app-design-v2");
      document.body.classList.remove("app-design-v1", "app-design-v3");

      if (window.clientConfig.splitGroupId > 5 && window.clientConfig.isWebDesktop) {
        document.body.classList.add("app-design-v2--result-v3");
      }
    }
    else {
      document.body.classList.add("app-design-v1");
      document.body.classList.remove("app-design-v2", "app-design-v3");
    }
  };

  onRouteChanged = () => {
    this.updateBodyClasses();
  };

  render() {
    if (this.state.isLoading) {
      return <Loading />;
    }

    return <div className={`${window.clientConfig.locale}`}>
      <Switch>
        <Route exact path={routes.INDEX} render={props => <IndexPageDefault {...props} site="classic" />} />
        <Route exact path={routes.ERROR} render={props => <ErrorPage {...props} site="classic" />} />
        <Route exact path={routes.CREATE} render={props => <CreatePage {...props} site="classic" />} />
        <Route exact path={routes.PROCESSING} render={props => <ProcessingPage {...props} site="classic" />} />
        <Route path={routes.RESULT} render={props => <ResultPageDefault {...props} site="classic" />} />
      </Switch>
      <ModalWrapper />
    </div>;
  }
}

App.contextType = AppContext;

const AppWithRouter = withRouter(App);

ReactDOM.render(
  <BrowserRouter>
    <AppContextProvider>
      <AppWithRouter />
    </AppContextProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

function toggleBodyClass(className, flag) {
  if (flag) {
    document.body.classList.add(className);
  } else {
    document.body.classList.remove(className);
  }
}