import CreativeConfig from "./CreativeConfig";
import {handlersNames} from "./handlers";
import {creativeGroups} from "./groups";

export const creativesConfigs = [

  // crop
  new CreativeConfig(creativeGroups.CROP, 4021, handlersNames.CROP).setSelected(true),

  // voila
  new CreativeConfig(creativeGroups.VOILA, 5289, handlersNames.SIMPLE).setSelected(true),

  // cartoon vector body
  new CreativeConfig(creativeGroups.CARTOON_VECTOR_BODY, 5142, handlersNames.CARTOON_VECTOR_BODY).setSelected(true),

  // disney collage
  new CreativeConfig(creativeGroups.DISNEY_COLLAGE, 5286, handlersNames.SIMPLE2).setSelected(true),

  // disney_with_5273
  new CreativeConfig(creativeGroups.DISNEY_WITH_5273, 4427, handlersNames.SIMPLE3),

  // disney 3d
  new CreativeConfig(creativeGroups.DISNEY, 4427, handlersNames.SIMPLE).setSelected(true),
  new CreativeConfig(creativeGroups.DISNEY, 4780, handlersNames.SIMPLE),
  new CreativeConfig(creativeGroups.DISNEY, 4246, handlersNames.SIMPLE).setHdTemplateId(4201),
  new CreativeConfig(creativeGroups.DISNEY, 4241, handlersNames.SIMPLE).setHdTemplateId(4177),

  // disney 2d
  new CreativeConfig(creativeGroups.DISNEY_2D, 4428, handlersNames.SIMPLE).setSelected(true),
  new CreativeConfig(creativeGroups.DISNEY_2D, 4260, handlersNames.SIMPLE).setHdTemplateId(4259),
  new CreativeConfig(creativeGroups.DISNEY_2D, 4262, handlersNames.SIMPLE).setHdTemplateId(4261),
  new CreativeConfig(creativeGroups.DISNEY_2D, 4244, handlersNames.SIMPLE).setHdTemplateId(4197),
  new CreativeConfig(creativeGroups.DISNEY_2D, 4245, handlersNames.SIMPLE).setHdTemplateId(4198),

  // collage
  new CreativeConfig(creativeGroups.COLLAGE, 4178, handlersNames.COLLAGE).setSelected(true),

  // archer
  new CreativeConfig(creativeGroups.ARCHER, 4437, handlersNames.SIMPLE).setSelected(true),
  new CreativeConfig(creativeGroups.ARCHER, 4234, handlersNames.SIMPLE).setHdTemplateId(4134),

  // simpsons
  new CreativeConfig(creativeGroups.SIMPSONS, 4578, handlersNames.SIMPLE).setSelected(true),
  new CreativeConfig(creativeGroups.SIMPSONS, 4554, handlersNames.SIMPLE),
  // new CreativeConfig(creativeGroups.ARCHER, 4235, handlersNames.SIMPLE_1).setHdTemplateId(4145),
  // new CreativeConfig(creativeGroups.ARCHER, 4233, handlersNames.SIMPLE_1).setHdTemplateId(4132),

  // caricature
  new CreativeConfig(creativeGroups.CARICATURE, 4373, handlersNames.SIMPLE).setSelected(true),
  new CreativeConfig(creativeGroups.CARICATURE, 4415, handlersNames.SIMPLE),

  // caricature_2
  new CreativeConfig(creativeGroups.CARICATURE_2, 4653, handlersNames.SIMPLE).setChanceToSelect(10),
  new CreativeConfig(creativeGroups.CARICATURE_2, 4654, handlersNames.SIMPLE).setChanceToSelect(10),
  new CreativeConfig(creativeGroups.CARICATURE_2, 4661, handlersNames.SIMPLE).setChanceToSelect(10),
  new CreativeConfig(creativeGroups.CARICATURE_2, 4662, handlersNames.SIMPLE).setChanceToSelect(10),
  new CreativeConfig(creativeGroups.CARICATURE_2, 4664, handlersNames.SIMPLE).setChanceToSelect(10),

  // vector
  new CreativeConfig(creativeGroups.VECTOR, 4252, handlersNames.VECTOR).setSelected(true),

  // t4532
  new CreativeConfig(creativeGroups.T4532, 4532, handlersNames.SIMPLE)
    .setSelected(!window.clientConfig.isChinaUser && !window.clientConfig.isJapanUser),
  new CreativeConfig(creativeGroups.T4532, 4462, handlersNames.SIMPLE)
    .setSelected(window.clientConfig.isChinaUser || window.clientConfig.isJapanUser),
  new CreativeConfig(creativeGroups.T4532, 4372, handlersNames.SIMPLE),

  // barbie
  new CreativeConfig(creativeGroups.BARBIE, 4543, handlersNames.SIMPLE).setSelected(true),

  // cartoon
  new CreativeConfig(creativeGroups.CARTOON, 4548, handlersNames.SIMPLE),
  new CreativeConfig(creativeGroups.CARTOON, 4558, handlersNames.SIMPLE),
  new CreativeConfig(creativeGroups.CARTOON, 4559, handlersNames.SIMPLE).setSelected(true),

  // cartoon2
  new CreativeConfig(creativeGroups.CARTOON2, 4558, handlersNames.SIMPLE).setSelected(true),

  // vectorpsp
  new CreativeConfig(creativeGroups.VECTORPSP, 5210, handlersNames.SIMPLE).setSelected(true),

  // cartoon animation
  new CreativeConfig(creativeGroups.CARTOON_ANIM, 4530, handlersNames.CARTOON_ANIM).setSelected(true),

  // cupid21_1
  new CreativeConfig(creativeGroups.CUPID21_1, 4601, handlersNames.CUPID).setSelected(true),

  // cupid21_2
  new CreativeConfig(creativeGroups.CUPID21_2, 4600, handlersNames.CUPID).setChanceToSelect(10),
  new CreativeConfig(creativeGroups.CUPID21_2, 4602, handlersNames.CUPID).setChanceToSelect(10),
  new CreativeConfig(creativeGroups.CUPID21_2, 4604, handlersNames.CUPID).setChanceToSelect(10),
  new CreativeConfig(creativeGroups.CUPID21_2, 4607, handlersNames.CUPID).setChanceToSelect(10),
  new CreativeConfig(creativeGroups.CUPID21_2, 4608, handlersNames.CUPID).setChanceToSelect(10),
  new CreativeConfig(creativeGroups.CUPID21_2, 4610, handlersNames.CUPID).setChanceToSelect(10),
  new CreativeConfig(creativeGroups.CUPID21_2, 4612, handlersNames.CUPID).setChanceToSelect(10),
  new CreativeConfig(creativeGroups.CUPID21_2, 4618, handlersNames.CUPID).setChanceToSelect(10),

  // popart
  new CreativeConfig(creativeGroups.POPART, 4721, handlersNames.SIMPLE).setSelected(true),

  // scarydolls
  // new CreativeConfig(creativeGroups.SCARYDOLLS, 4019, handlersNames.SIMPLE),
  // new CreativeConfig(creativeGroups.SCARYDOLLS, 4023, handlersNames.SIMPLE),
  new CreativeConfig(creativeGroups.SCARYDOLLS, 4040, handlersNames.SIMPLE),
  // new CreativeConfig(creativeGroups.SCARYDOLLS, 4054, handlersNames.SIMPLE),
  // new CreativeConfig(creativeGroups.SCARYDOLLS, 4087, handlersNames.SIMPLE),
  // new CreativeConfig(creativeGroups.SCARYDOLLS, 4048, handlersNames.SIMPLE), // anim
  // new CreativeConfig(creativeGroups.SCARYDOLLS, 4058, handlersNames.SIMPLE),
  // new CreativeConfig(creativeGroups.SCARYDOLLS, 4085, handlersNames.SIMPLE), // anim
  new CreativeConfig(creativeGroups.SCARYDOLLS, 4128, handlersNames.SIMPLE).setSelected(true),

  // carlafuentesart
  new CreativeConfig(creativeGroups.CARLAFUENTESART, 5032, handlersNames.SIMPLE).setSelected(true),

  // komuk3111
  new CreativeConfig(creativeGroups.KOMUK3111, 5033, handlersNames.SIMPLE).setSelected(true),

  // DISNEY_5276
  new CreativeConfig(creativeGroups.DISNEY_5276, 5276, handlersNames.SIMPLE).setSelected(true),

  // DISNEY_5277
  new CreativeConfig(creativeGroups.DISNEY_5277, 5277, handlersNames.SIMPLE).setSelected(true),
  /*copy of disney*/ new CreativeConfig(creativeGroups.DISNEY_5277, 4427, handlersNames.SIMPLE),
  /*copy of disney*/ new CreativeConfig(creativeGroups.DISNEY_5277, 4780, handlersNames.SIMPLE),
  /*copy of disney*/ new CreativeConfig(creativeGroups.DISNEY_5277, 4246, handlersNames.SIMPLE).setHdTemplateId(4201),
  /*copy of disney*/ new CreativeConfig(creativeGroups.DISNEY_5277, 4241, handlersNames.SIMPLE).setHdTemplateId(4177),

  // BARBIE_5278
  new CreativeConfig(creativeGroups.BARBIE_5278, 5278, handlersNames.SIMPLE).setSelected(true),

  // BARBIE_5279
  new CreativeConfig(creativeGroups.BARBIE_5279, 5279, handlersNames.SIMPLE).setSelected(true),
  new CreativeConfig(creativeGroups.BARBIE_5279, 5278, handlersNames.SIMPLE),

  // DISNEY_5336
  new CreativeConfig(creativeGroups.DISNEY_5336, 5336, handlersNames.SIMPLE).setSelected(true),

  // tab210622
  new CreativeConfig(creativeGroups.TAB_210622, 5302, handlersNames.SIMPLE).setSelected(window.clientConfig.splitGroupId === 6),
  new CreativeConfig(creativeGroups.TAB_210622, 5303, handlersNames.SIMPLE).setSelected(window.clientConfig.splitGroupId === 7),
  new CreativeConfig(creativeGroups.TAB_210622, 5304, handlersNames.SIMPLE).setSelected(window.clientConfig.splitGroupId === 8),

  // tab210623
  new CreativeConfig(creativeGroups.TAB_210623, 5308, handlersNames.SIMPLE).setSelected(true),
  new CreativeConfig(creativeGroups.TAB_210623, 5306, handlersNames.SIMPLE),

  // DISNEY2DMIX
  new CreativeConfig(creativeGroups.DISNEY2DMIX, 5355, handlersNames.SIMPLE).setSelected(true),

  // DISNEY_5357
  new CreativeConfig(creativeGroups.DISNEY_5357, 5357, handlersNames.SIMPLE).setSelected(true),

  // DISNEY_5361
  new CreativeConfig(creativeGroups.DISNEY_5361, 5361, handlersNames.SIMPLE).setSelected(true),

  // DISNEY_5365
  new CreativeConfig(creativeGroups.DISNEY_5365, 5365, handlersNames.SIMPLE).setSelected(true),

  // DISNEY_5366
  new CreativeConfig(creativeGroups.DISNEY_5366, 5366, handlersNames.SIMPLE).setSelected(true),

  // DISNEY_5367
  new CreativeConfig(creativeGroups.DISNEY_5367, 5367, handlersNames.SIMPLE).setSelected(true),
];