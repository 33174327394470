import React from "react";
import i18n from "../../i18n";
import Modal from "../../components/Modal";
import PropTypes from "prop-types";

export default class BodySelectRefreshTypeModal extends Modal {

  handleBodySelect = () => {
    this.props.onBodySelect();
    this.dismiss();
  };

  handleHeadSelect = () => {
    this.props.onHeadSelect();
    this.dismiss();
  };

  renderModal() {
    return <div className="modal-container modal-container-body-select">
      <div className="modal">
        <div className="btns-container">
          <button
            className="btn-select-style btn-select-head"
            children={i18n.t("refresh_body__btn_body")}
            onClick={this.handleBodySelect} />
          <button
            className="btn-select-style btn-select-body"
            children={i18n.t("refresh_body__btn_head")}
            onClick={this.handleHeadSelect} />
        </div>
        <button
          className="btn-cancel"
          children={i18n.t("btn_cancelV2")}
          onClick={() => this.dismiss()} />
      </div>
    </div>;
  }
}

BodySelectRefreshTypeModal.propTypes = {
  onBodySelect: PropTypes.func.isRequired,
  onHeadSelect: PropTypes.func.isRequired,
};