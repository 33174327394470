import {getHandlerByName} from "./handlers";
import Processing from "./Processing";
import {logEvent, userEvents} from "../utils/log";
import Creative from "./Creative";

export default class ProcessingManager {

  _listeners = [];
  _processing = null;
  _handlers = [];

  /** @return {Processing} */
  get processing() {
    return this._processing;
  }

  /** @param {Processing} processing */
  start = (processing) => {
    this.stop();

    this._processing = processing;

    if (!processing.hasExtra(Processing.EXTRA_STARTED_AT)) {
      processing.setExtra(Processing.EXTRA_STARTED_AT, Date.now());
    }

    window.localStorage.setItem("tcm:processing", processing.toJSON());

    this.tick();
    this.update();
  };

  update = () => {
    this.processing.creatives.forEach((creative) => {
      if (creative.isProcessed || creative.isFailed) {
        return;
      }

      const isActive = this._handlers.findIndex((item) => item.id === creative.id) > -1;
      if (isActive) {
        return;
      }

      const handler = getHandlerByName(creative.handler);
      if (handler === null) {
        throw new Error("Unrecognized handler name: " + creative.handler);
      }

      if (!creative.hasExtra(Creative.EXTRA_STARTED_AT)) {
        creative.setExtra(Creative.EXTRA_STARTED_AT, Date.now());

        logEvent(userEvents.CREATIVE_STARTED, {
          group: creative.group,
          template_id: creative.templateId,
        });
      }

      const handlerPromise = handler(this.processing, creative)
        .then((creative) => {
          this.tick();
          logEvent(userEvents.CREATIVE_PROCESSED, {
            group: creative.group,
            template_id: creative.templateId,
            processing_time: Date.now() - creative.getExtra(Creative.EXTRA_STARTED_AT),
          });
        })
        .catch((creative) => {
          this.tick();
          logEvent(userEvents.CREATIVE_FAILED, {
            group: creative.group,
            template_id: creative.templateId,
            processing_time: Date.now() - creative.getExtra(Creative.EXTRA_STARTED_AT),
            reason: creative.error ? creative.error.type : "unknown",
          });
        });

      this._handlers.push({
        id: creative.id,
        handler: handlerPromise,
      });
    });

    this.tick();
  };

  restore = () => {
    const storedValue = window.localStorage.getItem("tcm:processing");
    if (storedValue != null) {
      const processing = new Processing();
      processing.fromObject(JSON.parse(storedValue));

      return processing;
    }

    return null;
  };

  stop = () => {
    this._processing = null;
    // todo cancel promises
  };

  tick = () => {
    if (!this.processing) {
      return;
    }

    window.localStorage.setItem("tcm:processing", this.processing.toJSON());

    this._listeners.forEach((listener) => {
      listener.call(null, this.processing);
    });
  };

  addOnProcessingChangeHandler = (listener) => {
    this._listeners.push(listener);
  };

  removeOnProcessingChangeHandler = (listener) => {
    const pos = this._listeners.indexOf(listener);
    if (pos >= 0) {
      this._listeners.splice(pos, 1);
    }
  };

}