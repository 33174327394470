import React from "react";
import BodyTabDefault from "../result/BodyTab";
import SimpleTab from "./SimpleTab";

export default class BodyTab extends BodyTabDefault {

  renderResult = () => {
    const tabProps = {...this.props};
    tabProps.onRefreshButtonClick = this.handleRefreshButtonClick;
    tabProps.canBeRefreshed = true;

    return <SimpleTab {...tabProps} />;
  };
}
