export const genders = {
  male: "male",
  female: "female",
};

export const bodies = [
  {id: 5243, gender: genders.female},
  {id: 5246, gender: genders.female},
  {id: 5255, gender: genders.female},
  {id: 5256, gender: genders.female},
  {id: 5254, gender: genders.female},
  {id: 5253, gender: genders.female},
  {id: 5258, gender: genders.male},
  {id: 5214, gender: genders.male},
  {id: 5129, gender: genders.male},
  {id: 5131, gender: genders.male},
  {id: 5123, gender: genders.male},
  {id: 5206, gender: genders.male, previewFileName: "5206m"},
  {id: 5208, gender: genders.male},
  {id: 5173, gender: genders.male},
  {id: 5171, gender: genders.female},
  {id: 5149, gender: genders.male},
  {id: 5150, gender: genders.male},
  {id: 5151, gender: genders.male},
  {id: 5152, gender: genders.male},
  {id: 5153, gender: genders.male},
  {id: 5154, gender: genders.male},
  {id: 5126, gender: genders.male},
  {id: 5125, gender: genders.male},
  {id: 5095, gender: genders.male},
  {id: 5094, gender: genders.male},
  {id: 5093, gender: genders.male},
  {id: 5092, gender: genders.male},
  {id: 5088, gender: genders.male},
  {id: 5087, gender: genders.male},
  {id: 5074, gender: genders.male},
  {id: 5211, gender: genders.male},
  {id: 5080, gender: genders.male},
  {id: 5082, gender: genders.male},
  {id: 5085, gender: genders.male},
  {id: 5086, gender: genders.male},
  {id: 5213, gender: genders.female},
  {id: 5204, gender: genders.female},
  {id: 5205, gender: genders.female},
  {id: 5206, gender: genders.female, previewFileName: "5206f"},
  {id: 5207, gender: genders.female},
  {id: 5212, gender: genders.female},
  {id: 5174, gender: genders.female},
  {id: 5172, gender: genders.female},
  {id: 5128, gender: genders.female},
  {id: 5127, gender: genders.female},
  {id: 5104, gender: genders.female},
  {id: 5096, gender: genders.female},
  {id: 5083, gender: genders.female},
  {id: 5075, gender: genders.female},
  {id: 5081, gender: genders.female},
  {id: 5084, gender: genders.female},
];