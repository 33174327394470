import {assetUrl} from "../../utils/etc";
import i18n from "../../i18n";
import React from "react";
import PropTypes from "prop-types";
import {UNITE_COM_TAB_LINK} from "../../utils/constants";
import {ExternalUrl} from "../IndexPage";

function UniteTab(props) {
  const png = [
    assetUrl(`assets/img/bg/img-tab-unite.png`) + " 1x",
    assetUrl(`assets/img/bg/img-tab-unite@2x.png`) + " 2x",
    assetUrl(`assets/img/bg/img-tab-unite@3x.png`) + " 3x"
  ].join(", ");

  const webp = [
    assetUrl(`assets/img/bg/img-tab-unite.webp`) + " 1x",
    assetUrl(`assets/img/bg/img-tab-unite@2x.webp`) + " 2x",
    assetUrl(`assets/img/bg/img-tab-unite@3x.webp`) + " 3x"
  ].join(", ");

  return <div className="tab-custom tab-unite-container">
    <div className="bg-image-container">
      <picture>
        <source srcSet={webp} type="image/webp" />
        <img srcSet={png} alt="Demo" />
      </picture>
    </div>
    <div className="tab-unite-content">
      <h3 dangerouslySetInnerHTML={{__html: i18n.t("result__unite_tab_title")}} />

      <ExternalUrl
        url={UNITE_COM_TAB_LINK + "&utm_content=web"}
        onClick={props.onButtonClick}
        className="btn-go-unite"
        dangerouslySetInnerHTML={{__html: i18n.t("landing__unite_button")}} />
    </div>
  </div>;
}

UniteTab.propTypes = {
  onButtonClick: PropTypes.func.isRequired,
};

export default UniteTab;