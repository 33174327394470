import React from "react";
import i18n from "../i18n";
import {SvgSprite} from "./SvgSprite";
import ProcessingAdSlot from "./ProcessingAdSlot";

const LOADING_TEXT_INTERVAL = 3000;

const textsDefault = [
  // i18n.t("processing_text_1"),
  i18n.t("processing_text_2"),
  // i18n.t("processing_text_3"),
  i18n.t("processing_text_4"),
  i18n.t("processing_text_5"),
  i18n.t("processing_text_6"),
  // i18n.t("processing_text_7"),
];

const textsLove = [
  i18n.t("love_processing_text_1"),
  i18n.t("love_processing_text_2"),
  i18n.t("love_processing_text_3"),
  i18n.t("love_processing_text_4"),
  i18n.t("love_processing_text_5"),
  i18n.t("love_processing_text_6"),
  i18n.t("love_processing_text_7"),
  i18n.t("love_processing_text_8"),
  i18n.t("love_processing_text_9"),
  i18n.t("love_processing_text_10"),
];

export default class LoadingV2 extends React.Component {

  constructor(props) {
    super(props);

    this.texts = props.site === "love" ? textsLove : textsDefault;

    this.state = {
      textIndex: Math.floor(Math.random() * (this.texts.length - 1)),
    };

    this.updateTextIndexTimer = null;
    this.containerRef = React.createRef();
    this.containerRefObserver = new MutationObserver(() => {
      if (this.containerRef.current) {
        this.containerRef.current.style.height = "";
      }
    });
  }

  componentDidMount() {
    this.updateTextIndexTimer = setInterval(this.updateTextIndex, LOADING_TEXT_INTERVAL);
    if (this.containerRef.current) {
      this.containerRefObserver.observe(this.containerRef.current, {
        attributes: true,
        attributeFilter: ["style"]
      });
    }
  }

  componentWillUnmount() {
    this.containerRefObserver.disconnect();
    clearInterval(this.updateTextIndexTimer);
  }

  updateTextIndex = () => {
    if (this.state.textIndex === this.texts.length - 1) {
      this.setState({textIndex: 0});
    } else {
      this.setState({textIndex: this.state.textIndex + 1});
    }
  };

  render() {
    const textString = this.props.text
      || (this.props.withProcessingText ? this.texts[this.state.textIndex] : "&nbsp;");

    const withAds = this.props.withAds && window.appConfig.webAds.isEnabled;

    return <main className="loader-container" ref={this.containerRef}>
      <div className="loader-block">
        <div className="loader">
          <div className="shadow" />
          <div className="box" />
        </div>
        <p dangerouslySetInnerHTML={{__html: textString}} />

        {withAds && <React.Fragment>
          <p className="banner-title" dangerouslySetInnerHTML={{__html: i18n.t("webads_banner_title")}} />
          <div className="banner" align="center"><ProcessingAdSlot /></div>
        </React.Fragment>}

        <SvgSprite className="line-loader-mob" viewBox="0 0 414 437" icon="line-loader-mob" />
        <SvgSprite className="line-loader1" viewBox="0 0 1145 617" icon="line-loader1" />
        <SvgSprite className="line-loader2" viewBox="0 0 682 659" icon="line-loader2" />
      </div>
    </main>;
  }
}
