import React from "react";
import PropTypes from "prop-types";
import SimpleTab from "./SimpleTab";
import * as photolabApi from "../../photolab/api";
import PhotolabTaskBuilder from "../../photolab/PhotolabTaskBuilder";
import PhotolabTaskCollageMethod from "../../photolab/PhotolabTaskCollageMethod";
import PhotolabTaskImageUrl from "../../photolab/PhotolabTaskImageUrl";
import BodySelectRefreshTypeModal from "./BodySelectRefreshTypeModal";
import AppContext from "../../contexts/AppContext";
import Processing from "../../photolab/Processing";
import {bodies, genders} from "../../photolab/bodies";
import {SvgSprite} from "../../components/SvgSprite";
import ImageView from "../../components/ImageView";
import {assetUrl} from "../../utils/etc";
import i18n from "../../i18n";
import Creative from "../../photolab/Creative";

const views = {
  result: "result",
  head: "head",
  body: "body",
};

const bodiesTemplates = bodies.slice().shuffle();

class BodyTab extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      view: views.result,
      gender: genders.male,
      headResultUrl: null,
      bodySelectedTemplateId: null,
      bodiesProcessings: [],
      headTask: null,
    };

    this.bodiesContainerRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.bodiesContainerRef && this.bodiesContainerRef.current) {
      this.bodiesContainerRef.current.removeEventListener("wheel", this.handleBodiesContainterWheel);
      this.bodiesContainerRef.current.addEventListener("wheel", this.handleBodiesContainterWheel);
    }
  }

  handleBodiesContainterWheel = (e) => {
    if (this.bodiesContainerRef && this.bodiesContainerRef.current) {
      this.bodiesContainerRef.current.scrollLeft += e.deltaY;
    }
  };

  handleRefreshButtonClick = () => {
    this.context.pushModal(<BodySelectRefreshTypeModal
      key="ResultPage_BodyTab_BodySelectRefreshTypeModal"
      onBodySelect={() => this.handleRefreshBody()}
      onHeadSelect={() => this.handleRefreshHead()}
    />);
  };

  handleRefreshBody = () => {
    const processing = window.processingManager.processing;
    const creative = processing.getSelectedCreativeInGroup(this.props.group);
    const gender = (creative.getTask("gender").gender || genders.male).toLowerCase();

    const initialProcessing = {
      tries: 0,
      templateId: parseInt(creative.getExtra(Creative.EXTRA_BODY_TEMPLATE_ID)),
      isProcessing: false,
      isProcessed: true,
      isFailed: false,
      result: creative.getTask("body"),
    };

    const bodyPositionInList = bodiesTemplates.findIndex((t) => t.id === initialProcessing.templateId);
    if (bodyPositionInList > 0) {
      bodiesTemplates.unshift(...bodiesTemplates.splice(bodyPositionInList, 1));
    }

    this.setState({
      view: views.body,
      headResultUrl: creative.getTask("head").resultUrl,
      bodySelectedTemplateId: initialProcessing.templateId,
      bodiesProcessings: [initialProcessing],
      gender,
    });
  };

  handleBodyTemplateSelect = (templateId) => {
    this.setState({bodySelectedTemplateId: templateId});

    let processing = this.state.bodiesProcessings.find((processing) => {
      return processing.templateId === templateId;
    });

    if (processing) {
      return;
    }

    processing = {};
    processing.tries = 0;
    processing.templateId = templateId;
    processing.isProcessing = true;
    processing.isProcessed = false;
    processing.isFailed = false;

    this.state.bodiesProcessings.push(processing);

    this.setState({bodiesProcessings: this.state.bodiesProcessings.slice()}, () => {
      this.startBodyProcessing(processing);
    });
  };

  startBodyProcessing = (bodyProcessing) => {
    const taskConfig = new PhotolabTaskBuilder()
      .addImage(new PhotolabTaskImageUrl(this.state.headResultUrl))
      .addMethod(new PhotolabTaskCollageMethod(bodyProcessing.templateId))
      .setLanguage(window.clientConfig.lang)
      .build();

    photolabApi.photolabAddTask(taskConfig)
      .then((addTaskResult) => photolabApi.photolabWaitTask(addTaskResult.requestId, 1000))
      .then((taskResult) => {
        bodyProcessing.isProcessing = false;
        bodyProcessing.isProcessed = true;
        bodyProcessing.result = taskResult;

        this.setState({bodiesProcessings: this.state.bodiesProcessings.slice()});
      })
      .catch((err) => {
        bodyProcessing.isProcessing = false;
        bodyProcessing.isFailed = true;
        bodyProcessing.error = err;

        this.setState({bodiesProcessings: this.state.bodiesProcessings.slice()});
      });
  };

  handleBodyConfirm = (bodyProcessing) => {
    this.setState({view: views.result}, () => {
      this.props.onTemplateSelect(this.props.group, "body", {
        templateId: bodyProcessing.templateId,
        taskResult: bodyProcessing.result,
      });
    });
  };

  handleRefreshHead = () => {
    this.setState({view: views.head});

    const processing = window.processingManager.processing;
    const task = processing.getExtra(Processing.EXTRA_CARTOON_VECTOR_MEDIATOR_TASK);
    if (task) {
      this.setState({headTask: task});
    }

    const taskConfig = new PhotolabTaskBuilder()
      .setLanguage(processing.language)
      .addMethod(new PhotolabTaskCollageMethod(5148))
      .addImage(new PhotolabTaskImageUrl(processing.file.url))
      .build();

    photolabApi.photolabAddTask(taskConfig)
      .then((addTaskResult) => photolabApi.photolabWaitTask(addTaskResult.requestId, 2000))
      .then((taskResult) => {
        processing.setExtra(Processing.EXTRA_CARTOON_VECTOR_MEDIATOR_TASK, taskResult);
        this.setState({headTask: taskResult});
      })
      .catch((err) => {
        console.error(err);
      });
  };

  handleHeadSelectTemplate = (templateId) => {
    this.setState({view: views.result}, () => {
      this.props.onTemplateSelect(this.props.group, "head", {templateId});
    });
  };

  render() {
    switch (this.state.view) {
      case views.body: {
        return this.renderBodyChooser();
      }
      case views.head: {
        return this.renderHeadChooser();
      }
      default: {
        return this.renderResult();
      }
    }
  }

  handleToggleGender = () => {
    const gender = this.state.gender === genders.male
      ? genders.female
      : genders.male;

    this.setState({gender});
  };

  renderResult = () => {
    const tabProps = {...this.props};
    tabProps.onRefreshButtonClick = this.handleRefreshButtonClick;
    tabProps.canBeRefreshed = true;

    return <SimpleTab {...tabProps} />;
  };

  renderBodyChooser = () => {
    const templates = bodiesTemplates.filter((template) => {
      return template.gender === this.state.gender;
    });

    const bodyProcessing = this.state.bodiesProcessings.find((processing) => {
      return processing.templateId === this.state.bodySelectedTemplateId;
    });

    return <div className="body-chooser-container">
      {bodyProcessing && bodyProcessing.isProcessed && <div className="body-chooser-creative">
        <ImageView image={{url: bodyProcessing.result.resultUrl}} square />
        
        <button onClick={() => this.handleBodyConfirm(bodyProcessing)}>
          <SvgSprite viewBox="0 0 24 18" icon="icon-check" />
        </button>
      </div>}

      {bodyProcessing && bodyProcessing.isFailed && <div>
        failed
      </div>}

      {(!bodyProcessing || bodyProcessing.isProcessing) && <div className="body-chooser-loader">
        <div className="loader-roller-wrapper">
          <div className="loader-roller loader-roller1">
            <div className="roller" />
            <div className="roller" />
          </div>
          <div className="loader-roller loader-roller2">
            <div className="roller" />
            <div className="roller" />
          </div>
          <div className="loader-roller loader-roller3">
            <div className="roller" />
            <div className="roller" />
          </div>
        </div>
      </div>}

      <div className="btns-template-container" ref={this.bodiesContainerRef}>
        <button className={"btn-choice-gender " + (this.state.gender === genders.male ? "btn-switch-to-female" : "btn-switch-to-male")} onClick={this.handleToggleGender} key="switch-gender">
          <SvgSprite viewBox="0 0 12 12" icon="icon-choose-gender" />
          <span className="btn-choice-gender-title">
            {i18n.t(this.state.gender === genders.male ? "button_choice_gender_female" : "button_choice_gender_male")}
            <span dangerouslySetInnerHTML={{__html: i18n.t("button_choice_gender_text")}} />
          </span>
        </button>
        {templates.map((template) => {
          const styles = {
            backgroundImage: `url(${assetUrl(`assets/images/bodychooser/bodies/${template.previewFileName || template.id}.png`)})`,
          };

          return <button
            key={template.id}
            style={styles}
            className="active"
            dangerouslySetInnerHTML={{__html: "&nbsp;"}}
            onClick={() => this.handleBodyTemplateSelect(template.id)}
          />;
        })}
      </div>
    </div>;
  };

  renderHeadChooser = () => {
    return <div>
      head refresh (like vector tab)
    </div>;
  };
}

BodyTab.contextType = AppContext;
BodyTab.propTypes = {
  group: PropTypes.string.isRequired,
  file: PropTypes.object.isRequired,
  creatives: PropTypes.array.isRequired,
  withHD: PropTypes.bool,
  canBeRefreshed: PropTypes.bool,
  onImageLoaded: PropTypes.func.isRequired,
  onHideWatermarkButtonClick: PropTypes.func.isRequired,
  onShowWatermarkButtonClick: PropTypes.func.isRequired,
  onDownloadButtonClick: PropTypes.func.isRequired,
  onRefreshButtonClick: PropTypes.func.isRequired,
};

export default BodyTab;