import React from 'react';
import i18n from "../i18n";
import {Link} from "react-router-dom";
import FileChooseButton from "../components/FileChooseButton";
import routes from "../routes";
import Slider from "react-slick";
import {hitEvent, hits, logEvent, userEvents} from "../utils/log";
import AppContext from "../contexts/AppContext";
import {assetUrl, prefixRouteForSite, when} from "../utils/etc";
import {PHOTO_LAB_LINK, PLAY_MARKET_LINK, APPSTORE_LINK, MENTIONS} from "../utils/constants";
import {webviewOpenBrowser, webviewPreloadAd} from "../utils/webview";
import {SvgSprite} from "../components/SvgSprite";
import {formatLabsUpdatedAt, formatClassicUpdatedAt} from "../utils/date";

const sliderSettings = {
  autoplay: true,
  autoplaySpeed: 3000,
  dots: true,
  arrows: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerMode: true,
  centerPadding: '0',
  responsive: [{
    breakpoint: 440,
    settings: {centerPadding: '65px'}
  }, {
    breakpoint: 380,
    settings: {centerPadding: '45px'}
  }, {
    breakpoint: 340,
    settings: {centerPadding: '40px'}
  }],
};

const sliderSettingslove = {
  autoplay: true,
  autoplaySpeed: 3000,
  dots: true,
  arrows: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1
};

export default class IndexPage extends React.Component {

  constructor(props) {
    super(props);

    this.nativeAdsPreloadIsCalled = false;
  }

  componentDidMount() {
    if (window.clientConfig.isWebview) {
      const percent = window.appConfig.webview.uploadEnabledPercent;
      const isEnabled = percent !== 0 && Math.random() <= percent;

      const country = (window.clientConfig.webviewParams.country || "").toLowerCase();
      const isDisabledCountry = window.appConfig.webview.uploadDisabledCountries.indexOf(country) > -1;

      const clientId = window.clientConfig.webviewParams["aid"]
          || window.clientConfig.webviewParams["vicman_unified_id"];

      const clientIdChar = clientId.charAt(clientId.length - 2).toUpperCase();

      const isEnabledByClient = (clientIdChar === "0" || clientIdChar === "2")
         && (window.clientConfig.lang === "ja" || window.clientConfig.lang === "en");

      // if (isEnabledByClient || !isEnabled || isDisabledCountry) {
      //   this.props.history.replace(routes.FEED_BANNER);
      //   return;
      // }
    }

    this.webviewTabSelected$ = window.webviewHolders.tabSelected.subscribe((v) => {
      if (v > 0) {
        this.handleWebviewTabSelected();
      }
    }, true);

    if (window.clientConfig.isWebDesktop) {
      hitEvent(hits.PAGE_INDEX_WEB_DESKTOP);
    } else if (window.clientConfig.isWebMobile) {
      hitEvent(hits.PAGE_INDEX_WEB_MOBILE);
    }

    logEvent(userEvents.PAGE_INDEX);
  }

  componentWillUnmount() {
    this.webviewTabSelected$ && this.webviewTabSelected$.unsubscribe();
  }

  handleWebviewTabSelected = () => {
    const shouldBePreloadAds = this.nativeAdsPreloadIsCalled === false
      && window.appConfig.nativeAds.isEnabled
      && window.clientConfig.isPro === false;

    if (shouldBePreloadAds) {
      webviewPreloadAd();
      this.nativeAdsPreloadIsCalled = true;
    }
  };

  handleFileSelected = (file) => {
    hitEvent(hits.PHOTO_SELECT);
    logEvent(userEvents.PHOTO_SELECT, {page: "index"});

    this.props.history.push(prefixRouteForSite(routes.CREATE, this.props.site), {file});
  };

  handlePhotoLabClick = (e) => {
    if (window.clientConfig.isWebview) {
      e.preventDefault();
      webviewOpenBrowser(PHOTO_LAB_LINK);
    }
  };

  handleLoveButtonClick = () => {
    const deeplink = "toonme://navigate/tab?id=6500";

    if (window.clientConfig.isWebviewAndroid) {
      window.location.href = deeplink;
    } else if (window.clientConfig.isWebviewIOS) {
      webviewOpenBrowser(deeplink);
    } else {
      window.location.href = window.appConfig.paths.app.replace("://", "://cupid.");
    }
  };

  handleGetAppButtonClick = () => {
    hitEvent(hits.GET_APP_CLICK);
    logEvent(userEvents.GET_APP_CLICK, {type: "landing_button"});

    setTimeout(() => {
      window.location.href = window.clientConfig.platform.os.toLowerCase() === "ios"
        ? APPSTORE_LINK
        : PLAY_MARKET_LINK;
    }, 300);
  };

  getPrivacyLink = () => {
    let link = "https://pho.to/privacy";

    const targetLang = ["de", "es", "fr", "it", "pt", "ru"].indexOf(window.clientConfig.lang);
    if (targetLang >= 0) {
      link += "_" + window.clientConfig.lang;
    }

    return link;
  };

  handleBackToClassic = () => {
    if (this.props.site === "love" && window.clientConfig.isWeb) {
      window.location.href = window.appConfig.paths.app;
    } else {
      this.props.history.push(routes.INDEX);
    }
  };

  renderWebviewButton = () => {
    return <FileChooseButton
      onFileSelected={this.handleFileSelected}
      className="btn-upload-foto"
      children={i18n.t("landing__select_photo_button")} />;
  };

  renderWebButton = () => {
    // if (window.clientConfig.isWebMobile && this.props.site !== "love") {
    //   return <button
    //     className="btn-upload-foto btn-get-app"
    //     children={i18n.t("landing__get_the_app_button")}
    //     onClick={this.handleGetAppButtonClick} />;
    // }

    return <FileChooseButton
      onFileSelected={this.handleFileSelected}
      className="btn-upload-foto"
      children={i18n.t("landing__select_photo_button")} />;
  };

  renderButton = () => {
    return window.clientConfig.isWebview
      ? this.renderWebviewButton()
      : this.renderWebButton();
  };

  render() {
    const method = when(this.props.site, {
      "classic": this.renderMain,
      "labs": this.renderLabs,
      "love": this.renderLove,
    });

    return method();
  }

  renderLove = () => {
    const indexImagesSplitGroups = {
      "default": [142, 143, 144, 145, 146, 147]
    };

    const indexImages = indexImagesSplitGroups["default"];

    const titleContactsFormText = i18n.t("title_contacts_form")
      .replace("Photo Lab", "<a href='https://y3nay.app.goo.gl/toonmecompl' target='_blank'>Photo Lab</a>");

    const indexRoute = window.clientConfig.isWebview ? routes.LOVE_INDEX : routes.INDEX;

    const isIos = ["ios"].indexOf(window.clientConfig.platform.os.toLowerCase()) > -1;
    const isAndroid = ["android", "android os"].indexOf(window.clientConfig.platform.os.toLowerCase()) > -1;

    return <main className="main-page love">
      <div className="container">
        <header className="header" hidden={window.clientConfig.isWebview}>
          <div className="header-main-content">
            <Link to={indexRoute} className="logo">
              toonme&nbsp;cupid
            </Link>
            <ExternalUrl url={PHOTO_LAB_LINK} children={i18n.t("btn_photo_lab")} className="btn-photo-lab" onClick={this.handlePhotoLabClick} />
          </div>
        </header>

        <h1 className="main-page-title" dangerouslySetInnerHTML={{__html: i18n.t("landing__title_love")}} />
        <p className="main-page-subtitle" dangerouslySetInnerHTML={{__html: i18n.t("landing__subtitle_love")}} />

        <section className="wr-slider-images">
          <Slider {...sliderSettingslove}>
            {indexImages.map((number) => <SlideView key={number} number={number} />)}
          </Slider>
        </section>

        {this.renderButton()}

        <button
          className="btn-back-classic"
          hidden={window.clientConfig.isWebview}
          onClick={this.handleBackToClassic}>
          <SvgSprite viewBox="0 0 32 54" icon="icon-back" />
          <p dangerouslySetInnerHTML={{__html: i18n.t("back_classic_btn")}} />
        </button>

        <p hidden={window.clientConfig.isWebview} className="updated-at">{i18n.t("updated_at_love")}</p>

        <div className="btns-market-container" hidden={window.clientConfig.isWebview}>
          <ExternalUrl className="btn-store" url={APPSTORE_LINK} hidden={isAndroid} onClick={() => logEvent(userEvents.GET_APP_CLICK, {type: "footer_v1"})}>
            <SvgSprite viewBox="0 0 66 80" icon="icon-ios-store" />
            <div className="btn-store-content" dangerouslySetInnerHTML={{__html: i18n.t("btn_store__ios")}} />
          </ExternalUrl>
          <ExternalUrl className="btn-store" url={PLAY_MARKET_LINK} hidden={isIos} onClick={() => logEvent(userEvents.GET_APP_CLICK, {type: "footer_v1"})}>
            <SvgSprite viewBox="0 0 64 72" icon="icon-android-store" />
            <div className="btn-store-content" dangerouslySetInnerHTML={{__html: i18n.t("btn_store__android")}} />
          </ExternalUrl>
        </div>

        <p hidden={window.clientConfig.isWebview}
           className="title-contacts-form"
           dangerouslySetInnerHTML={{__html: titleContactsFormText}} />

        <footer className="footer" hidden={window.clientConfig.isWebview}>
          <a href="mailto:contact@toonme.com" className="btn-contacts-form btn-contacts-form-footer" dangerouslySetInnerHTML={{__html: i18n.t("btn_contacts_form")}} />
          <ExternalUrl url={this.getPrivacyLink()} children="Privacy Policy" className="btn-privacy" />
        </footer>
      </div>
    </main>;
  };

  renderLabs = () => {
    const indexImagesSplitGroups = {
      "default": [43, 39, 40, 41, 42]
    };

    const indexImages = indexImagesSplitGroups["default"];

    return <main className="main-page labs">
      <div className="container">
        <h1 className="main-title" dangerouslySetInnerHTML={{__html: i18n.t("landing__title_labs")}} />
        <p className="main-subtitle" dangerouslySetInnerHTML={{__html: i18n.t("landing__subtitle_labs")}} />

        <button
          className="btn-to-toonme"
          dangerouslySetInnerHTML={{__html: i18n.t("landing__labs_back_button")}}
          onClick={this.handleBackToClassic} />

        <section className="wr-slider">
          <Slider {...sliderSettings}>
            {indexImages.map((number) => <SlideView key={number} number={number} />)}
          </Slider>
          <div className="square square-big" />
          <div className="square square-med" />
          <div className="square square-small" />
        </section>

        {this.renderButton()}

        <p className="updated-at">{i18n.t("updated_at_labs", {date: formatLabsUpdatedAt()})}</p>
      </div>
    </main>;
  };

  renderMain = () => {
    const indexImages = [10, 11, 12, 13, 14, 15, 16];

    const titleContactsFormText = i18n.t("title_contacts_form")
      .replace("Photo Lab", "<a href='https://y3nay.app.goo.gl/toonmecompl' target='_blank'>Photo Lab</a>");

    return <main className="main-page">
      <div className="container">
        <img src="/assets/img/bosslogo.png"
             style={{display: "block", margin: "20px auto", width: "100%", maxWidth: 250}}
             alt="Boss.toonme.com" />
        <h1
          className="main-title"
          children="Who is all bossssyy and totally cartoooon? Time to meet your inner baby!" />

          <p dangerouslySetInnerHTML={{__html: i18n.t("landing__subtitle")}} />

        <section className="wr-slider">
          <Slider {...sliderSettings}>
            {indexImages.map((number) => <SlideView key={number} number={number} />)}
          </Slider>
        </section>

        {this.renderButton()}

        {/*<div className="love-btn-container" hidden={window.clientConfig.isWebview}>*/}
        {/*  <p dangerouslySetInnerHTML={{__html: i18n.t("love__btn_title")}} />*/}
        {/*  <button className="btn-love" onClick={this.handleLoveButtonClick}>*/}
        {/*    <p dangerouslySetInnerHTML={{__html: i18n.t("landing__love_choice")}} />*/}
        {/*  </button>*/}
        {/*  <SvgHeartIcon classNameIcon="icon-heart icon-heart-1" />*/}
        {/*  <SvgHeartIcon classNameIcon="icon-heart icon-heart-2" />*/}
        {/*  <SvgHeartIcon classNameIcon="icon-heart icon-heart-3" />*/}
        {/*  <SvgHeartIcon classNameIcon="icon-heart icon-heart-4" />*/}
        {/*  <SvgHeartIcon classNameIcon="icon-heart icon-heart-5" />*/}
        {/*</div>*/}

        <div>
          <ExternalUrl
            url={"https://toonme.com"}
            className="upload-foto-text"
            dangerouslySetInnerHTML={{__html: i18n.t("landing__labs_back_button")}} />
        </div>

        <div className="footer-links" hidden={window.clientConfig.isWebview}>
          <a href={PHOTO_LAB_LINK} target="_blank" rel="noopener noreferrer" onClick={this.handlePhotoLabClick}>by Photo Lab</a>
          <ExternalUrl url={this.getPrivacyLink()} children="Privacy Policy" />
        </div>

        <p className="updated-at" dangerouslySetInnerHTML={{__html: i18n.t("updated_at_labs", {date: formatClassicUpdatedAt()})}} />

        {/*<div className="btns-market-container" hidden={window.clientConfig.isWebview}>*/}
        {/*  <a className="btn-store" href={APPSTORE_LINK} target="_blank" rel="noopener noreferrer">*/}
        {/*    <SvgSprite viewBox="0 0 66 80" icon="icon-ios-store" />*/}
        {/*    <div className="btn-store-content" dangerouslySetInnerHTML={{__html: i18n.t("btn_store__title", {market: i18n.t("btn_store__ios")})}} />*/}
        {/*  </a>*/}
        {/*  <a className="btn-store" href={PLAY_MARKET_LINK} target="_blank" rel="noopener noreferrer">*/}
        {/*    <SvgSprite viewBox="0 0 64 72" icon="icon-android-store" />*/}
        {/*    <div className="btn-store-content" dangerouslySetInnerHTML={{__html: i18n.t("btn_store__title", {market: i18n.t("btn_store__android")})}} />*/}
        {/*  </a>*/}
        {/*</div>*/}

        {/*<div className="logo-container" hidden={window.clientConfig.isWebview}>*/}
        {/*  <div className="logo-container1">*/}
        {/*    <ExternalUrl url={MENTIONS["sunmasthead"]} children={LogoView("sunmasthead")} />*/}
        {/*    <ExternalUrl url={MENTIONS["inquirerdotnet-2016"]} children={LogoView("inquirerdotnet-2016")} />*/}
        {/*    <ExternalUrl url={MENTIONS["this-morning-tv-logo"]} children={LogoView("this-morning-tv-logo")} />*/}
        {/*    <ExternalUrl url={MENTIONS["boredpanda"]} children={LogoView("boredpanda")} />*/}
        {/*    <ExternalUrl url={MENTIONS["techtudo"]} children={LogoView("techtudo")} />*/}
        {/*    <ExternalUrl url={MENTIONS["the-star"]} children={LogoView("the-star")} />*/}
        {/*    <ExternalUrl url={MENTIONS["ntv"]} children={LogoView("ntv")} />*/}
        {/*    <ExternalUrl url={MENTIONS["allocine"]} children={LogoView("allocine")} />*/}
        {/*  </div>*/}
        {/*  <div className="logo-container2">*/}
        {/*    <ExternalUrl url={MENTIONS["msn"]} children={LogoView("msn")} />*/}
        {/*    <ExternalUrl url={MENTIONS["logo"]} children={LogoView("logo")} />*/}
        {/*    <ExternalUrl url={MENTIONS["logo-money"]} children={LogoView("logo-money")} />*/}
        {/*    <ExternalUrl url={MENTIONS["didgital-camera"]} children={LogoView("didgital-camera")} />*/}
        {/*    <ExternalUrl url={MENTIONS["logo-grazia-full"]} children={LogoView("logo-grazia-full")} />*/}
        {/*    <ExternalUrl url={MENTIONS["urgant"]} children={LogoView("urgant")} />*/}
        {/*    <ExternalUrl url={MENTIONS["webtekno"]} children={LogoView("webtekno")} />*/}
        {/*    <ExternalUrl url={MENTIONS["basic-thinking"]} children={LogoView("basic-thinking")} />*/}
        {/*  </div>*/}
        {/*</div>*/}

        <p hidden={window.clientConfig.isWebview}
           className="title-contacts-form"
           dangerouslySetInnerHTML={{__html: titleContactsFormText}} />
      
        <a href="mailto:contact@toonme.com"
           className="btn-contacts-form"
           hidden={window.clientConfig.isWebview}
           dangerouslySetInnerHTML={{__html: i18n.t("btn_contacts_form")}} />
      </div>
    </main>;
  }
}

IndexPage.contextType = AppContext;

export function LogoView(name) {
  const png = [
    assetUrl(`assets/img/logos/${name}.png`) + " 1x",
    assetUrl(`assets/img/logos/${name}@2x.png`) + " 2x"
  ].join(", ");

  return <picture>
    <img srcSet={png} alt="Logo" />
  </picture>;
}

export function ExternalUrl({className, url, children, onClick, hidden, dangerouslySetInnerHTML}) {
  if (hidden) {
    return null;
  }

  return <a
    href={url}
    className={className}
    onClick={onClick}
    children={children}
    dangerouslySetInnerHTML={dangerouslySetInnerHTML}
    target="_blank"
    rel="noopener noreferrer" />;
}

export function SlideView({number}) {
  const jpg = [
    assetUrl(`assets/img/slider/img-${number}.jpg`) + " 1x",
    assetUrl(`assets/img/slider/img-${number}@2x.jpg`) + " 2x"
  ].join(", ");

  const webp = [
    assetUrl(`assets/img/slider/img-${number}.webp`) + " 1x",
    assetUrl(`assets/img/slider/img-${number}@2x.webp`) + " 2x"
  ].join(", ");

  return <div className="slide-container">
    <div className="slide">
      <picture>
        <source srcSet={`assets/img/slider/boss_${number}.webp`} type="image/webp" />
        <img srcSet={`assets/img/slider/boss_${number}.jpg`} alt="Demo" />
      </picture>
    </div>
  </div>;
}

export function SvgHeartIcon({classNameIcon}) {
  return <svg viewBox="0 0 56 51" className={classNameIcon}>
    <defs>
      <linearGradient id="fj1g7y0cua" x1="50%" x2="50%" y1="0%" y2="99.568%">
        <stop offset="0%" stopColor="#F0679F"/>
        <stop offset="100%" stopColor="#F0679F" stopOpacity=".3"/>
      </linearGradient>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g fill="url(#fj1g7y0cua)" fillRule="nonzero" transform="translate(-126 -1870)">
        <g>
            <path d="M35.25 0c-2.605 0-4.993.818-7.098 2.432-2.018 1.546-3.361 3.517-4.152 4.95-.791-1.434-2.134-3.404-4.152-4.95C17.743.818 15.355 0 12.75 0 5.481 0 0 5.893 0 13.707 0 22.15 6.838 27.925 17.19 36.67c1.759 1.485 3.752 3.168 5.823 4.964.273.237.623.367.987.367s.714-.13.987-.367c2.072-1.796 4.064-3.479 5.823-4.965C41.162 27.925 48 22.15 48 13.708 48 5.892 42.519 0 35.25 0z" transform="rotate(10 -10621.893 1701.893)"/>
        </g>
      </g>
    </g>
  </svg>;
}