export default class PhotolabTaskCollageMethod {

  static ID_GENDER_CLASSIFIER = "gender_classifier";

  constructor(templateId) {
    this._name = "collage";
    this._params = {
      template_name: templateId,
    };
  }

  get name() {
    return this._name;
  }

  get params() {
    return this._params;
  }
}