import {createMd5Token} from "../utils/text";

export default class Creative {

  static STATUS_FAILED = -1;
  static STATUS_PENDING = 0;
  static STATUS_PROCESSED = 1;

  static EXTRA_HD_TEMPLATE_ID = "hd_template_id";
  static EXTRA_COLLAGE_HASH = "collage_hash";
  static EXTRA_COLLAGE_GROUPS = "collage_groups";
  static EXTRA_STARTED_AT = "started_at";
  static EXTRA_BODY_TEMPLATE_ID = "body_template_id";

  _data = {};

  constructor() {
    this._data.fileId = 0;
    this._data.status = Creative.STATUS_PENDING;
    this._data.group = "";
    this._data.alias = "";
    this._data.templateId = 0;
    this._data.handler = "";
    this._data.isSelected = false;
    this._data.isRefreshed = false;
    this._data.tasks = {};
    this._data.files = {};
    this._data.error = null;
    this._data.extra = {};
    this._data.resultUrl = null;
  }

  fromObject(obj) {
    this._data = {};

    Object.keys(obj).forEach((key) => {
      this._data[key] = obj[key];
    });
  }

  copy() {
    const copiedData = JSON.parse(JSON.stringify(this._data));
    copiedData.id = createMd5Token(
      Date.now(),
      this._data.group,
      this._data.templateId,
      this._data.handler
    );

    const copying = new Creative();
    copying.fromObject(copiedData);

    return copying;
  }

  /** @param {CreativeConfig} config */
  configureByConfig(config) {
    this._data.id = createMd5Token(
      Date.now(),
      config.group,
      config.templateId,
      config.handler
    );

    this._data.group = config.group;
    this._data.templateId = config.templateId;
    this._data.handler = config.handler;

    if (config.hdTemplateId) {
      this.setExtra(Creative.EXTRA_HD_TEMPLATE_ID, config.hdTemplateId);
    }

    Object.keys(config.extra).forEach((key) => {
      this.setExtra(key, config.extra[key]);
    });

    return this;
  }

  setFileId(fileId) {
    this._data.fileId = fileId;
    return this;
  }

  setAlias(alias) {
    this._data.alias = alias;
    return this;
  }

  setAsSelected(flag) {
    this._data.isSelected = !!flag;
    return this;
  }

  setAsRefreshed(flag) {
    this._data.isRefreshed = !!flag;
    return this;
  }

  markAsProcessed(resultUrl) {
    this._data.resultUrl = resultUrl;
    this._data.status = Creative.STATUS_PROCESSED;
    return this;
  }

  markAsFailed(error) {
    this._data.error = error;
    this._data.status = Creative.STATUS_FAILED;
    return this;
  }

  getTask(name) {
    return this._data.tasks[name] || null;
  }

  setTask(name, task) {
    this._data.tasks[name] = task;
    return this;
  }

  getFile(key) {
    return this._data.files[key] || null;
  }

  setFile(key, url) {
    this._data.files[key] = url;
    return this;
  }

  get id() {
    return this._data.id;
  }

  get fileId() {
    return this._data.fileId;
  }

  get group() {
    return this._data.group;
  }

  get templateId() {
    return this._data.templateId;
  }

  get handler() {
    return this._data.handler;
  }

  get isSelected() {
    return this._data.isSelected;
  }

  get isRefreshed() {
    return this._data.isRefreshed;
  }

  get isProcessed() {
    return this._data.status === Creative.STATUS_PROCESSED;
  }

  get isFailed() {
    return this._data.status === Creative.STATUS_FAILED;
  }

  get isPending() {
    return this._data.status === Creative.STATUS_PENDING;
  }

  get resultUrl() {
    return this._data.resultUrl;
  }
  
  get error() {
    return this._data.error;
  }

  get alias() {
    return this._data.alias;
  }

  get data() {
    return this._data;
  }

  inGroup(group) {
    return this._data.group === group;
  }

  setExtra(key, value) {
    this._data.extra[key] = value;
    return this;
  }

  hasExtra(key) {
    return this._data.extra.hasOwnProperty(key);
  }

  getExtra(key, defaultValue) {
    return this._data.extra[key] || defaultValue;
  }
}