import React from 'react';
import routes from "../routes";
import * as api from "../utils/api";
import {hitEvent, hits, logEvent, userEvents} from "../utils/log";
import Loading from "../components/Loading";
import LoadingV2 from "../components/LoadingV2";
import AppContext from "../contexts/AppContext";
import {ApiResponseError} from "../utils/api";
import {webviewPreloadAd, webviewShowAd} from "../utils/webview";
import clientStorage from "../utils/client-storage";
import {prefixRouteForSite, when} from "../utils/etc";
import {creativesConfigs} from "../photolab/config";
import {collageGroupCreatives, collageGroups, creativeGroups, sitesGroups} from "../photolab/groups";
import Processing from "../photolab/Processing"
import Creative from "../photolab/Creative";
import {generatePath} from "react-router";

export default class ProcessingPage extends React.Component {

  componentDidMount() {
    window.processingManager.addOnProcessingChangeHandler(this.handleProcessingChange);

    const processing = window.processingManager.restore();
    if (processing && processing.site === this.props.site && processing.file.id === parseInt(this.props.match.params.id)) {
      window.processingManager.start(processing);
    } else {
      this.fetchFile(this.props.match.params.id);
    }

    if (window.clientConfig.isWebview && window.appConfig.nativeAds.isEnabled) {
      const isSkipAd = this.props.location.state && this.props.location.state.skipAd === true;

      const nativeAdsIsSupport = window.clientConfig.isWebviewAndroid
        ? (window.clientConfig.webviewParams.version_code >= 153)
        : (window.clientConfig.webviewParams.version_code >= 703);

      if (nativeAdsIsSupport && window.clientConfig.isPro === false && !isSkipAd) {
        hitEvent(hits.NATIVE_ADS_SHOW_REQUEST);
        webviewShowAd(this.handleWebviewAdShown, this.handleWebviewAdClosed);
      }
    }

    clientStorage.incrementProcessingsAmount();
  }

  componentWillUnmount() {
    window.processingManager.removeOnProcessingChangeHandler(this.handleProcessingChange);
  }

  handleWebviewAdShown = (params) => {
    hitEvent(hits.NATIVE_ADS_SHOWN);
  };

  handleWebviewAdClosed = (params) => {
    webviewPreloadAd();
  };

  startProcessing = (file) => {
    let groups = sitesGroups[this.props.site].slice();

    const processing = new Processing();
    processing.setFile(file);
    processing.setSite(this.props.site);
    processing.setGroups(groups);
    processing.setLanguage(window.clientConfig.lang);
    processing.setExtra(Processing.EXTRA_CREATED_AT, Date.now());
    processing.setWatermarkName(window.clientConfig.isWeb ? "default_web" : "default_webview");

    processing.groups.forEach((group) => {
      if (group === creativeGroups.COLLAGE) {
        return;
      }

      const configs = creativesConfigs.filter((config) => config.group === group);
      let selectedConfig = configs.find((config) => config.isSelected);
      if (!selectedConfig) {
        const sum = configs.reduce((value, config) => value + config.chanceToSelect, 0);
        if (sum === 0) {
          selectedConfig = configs[0];
        } else {
          let chance = Math.random() * sum;
          for (let i = 0; i < configs.length; i++) {
            chance -= configs[i].chanceToSelect;
            if (chance <= 0) {
              selectedConfig = configs[i];
              break;
            }
          }

          if (!selectedConfig) {
            selectedConfig = configs[0];
          }
        }
      }

      processing.addCreative(new Creative()
        .configureByConfig(selectedConfig)
        .setAsSelected(true));
    });

    window.processingManager.start(processing);
  };

  /** @param {Processing} processing */
  handleProcessingChange = (processing) => {
    console.info("%cProcessingPage::handleProcessingChange", "font-weight: bold; color: #009", processing);

    const selectedCreatives = processing.creatives.filter((c) => c.isSelected && !c.inGroup(creativeGroups.CROP));
    const processedCreatives = selectedCreatives.filter((c) => c.isProcessed);
    const failedCreatives = selectedCreatives.filter((c) => c.isFailed);

    if (processedCreatives.length > 0) {
      hitEvent(hits.PROCESSING_PROCESSED);

      if (!clientStorage.getHasProcessedProcessing()) {
        clientStorage.setHasProcessedProcessing(true);
        hitEvent(hits.PROCESSING_PROCESSED_FIRST);
      }

      logEvent(userEvents.PROCESSING_PROCESSED, {
        elapsed_time_ms: Date.now() - processing.getExtra(Processing.EXTRA_STARTED_AT),
      });

      this.props.history.replace({
        pathname: generatePath(prefixRouteForSite(routes.RESULT, this.props.site)),
        state: {file: processing.file}
      });
    } else if (failedCreatives.length === selectedCreatives.length) {
      const failedCreative = failedCreatives[0];
      const isPhotolab = failedCreative.error.type === "photolab";

      hitEvent(isPhotolab ? hits.PROCESSING_FAILED_PHOTOLAB : hits.PROCESSING_FAILED);
      logEvent(userEvents.PROCESSING_FAILED, {
        elapsed_time_ms: Date.now() - processing.getExtra(Processing.EXTRA_STARTED_AT),
      });

      window.processingManager.stop();

      this.props.history.replace({
        pathname: prefixRouteForSite(routes.ERROR, this.props.site),
        state: {
          message: isPhotolab ? failedCreative.error.message : undefined,
          photolabCode: isPhotolab ? failedCreative.error.code : undefined,
        }
      });
    }
  };

  fetchFile = (fileId) => {
    const params = {
      site: this.props.site
    };

    api.fetchFile(fileId, params)
      .then(this.startProcessing)
      .catch((err) => {
        console.error(err);

        if (err instanceof ApiResponseError) {
          console.error(err);
          this.props.history.replace(prefixRouteForSite(routes.ERROR, this.props.site));
        } else {
          this.fetchTimer = setTimeout(() => this.fetchFile(fileId), 2000);
        }
      });
  };

  render() {
    return (window.appConfig.designVersion === "v2" || this.props.site === "love")
      ? <LoadingV2 withAds withProcessingText site={this.props.site} />
      : <Loading withProcessingText site={this.props.site} />;
  }
}

ProcessingPage.contextType = AppContext;