import React from 'react';
import i18n from "../../i18n";
import {Link} from "react-router-dom";
import routes from "../../routes";
import {ExternalUrl} from "../IndexPage";
import {PHOTO_LAB_LINK} from "../../utils/constants";
import AppContext from "../../contexts/AppContext";
import ResultPage from "../result/ResultPage";
import SimpleTab from "./SimpleTab";
import VectorTab from "./VectorTab";
import ClassicTab from "./ClassicTab";
import DownloadModal from "./DownloadModal";
import RemoveLogoModal from "./RemoveLogoModal";
import BodyTab from "./BodyTab";

export default class ResultPageV3 extends ResultPage {

  showDownloadModal = (creative, withHD) => {
    this.context.pushModal(<DownloadModal
      key="ResultPageV3-DownloadModal"
      site={this.props.site}
      history={this.props.history}
      tab={this.state.tab}
      file={this.state.file}
      creative={creative}
      withHD={withHD}
      downloadHandler={this.handleDownload}
      shareHandler={this.handleShare}
    />);
  };

  showRemoveLogoModal = () => {
    this.context.pushModal(<RemoveLogoModal
      key="ResultPageV3-RemoveLogoModal"
      onShowButtonClick={this.handleShowWatermak}
      onHideButtonClick={this.handleHideWatermak}
    />);
  };

  renderSimpleTab = (props) => <SimpleTab {...props} />;
  renderVectorTab = (props) => <VectorTab {...props} onTemplateSelect={this.handleVectorRefreshButtonClick} />;
  renderBodyTab = (props) => <BodyTab {...props} onTemplateSelect={this.handleBodyRefreshTemplateSelect} />;
  renderClassicTab = (props) => <ClassicTab {...props} onButtonClick={this.handleClassicButtonClick} />;

  renderView = ({commonTabSettings, showTabs}) => {
    const indexRoute = window.clientConfig.isWebview ? routes.LOVE_INDEX : routes.INDEX;

    return <main className={"collage-page" + (this.props.site === "labs" ? " labs-container" : "")}>
      <div className="container">
        <header className="header" hidden={window.clientConfig.isWebview}>
          <div className="header-main-content">
            <Link to={indexRoute} className="logo">
              toonme&nbsp;cupid
            </Link>
            <ExternalUrl url={PHOTO_LAB_LINK} children={i18n.t("btn_photo_lab")} className="btn-photo-lab" onClick={this.handlePhotoLabClick} />
          </div>
        </header>
      </div>
      <div className="collage-page-content container">
        <div className="tabs-container">
          {showTabs.map((group) => this.renderTab(group))}
        </div>
        {this.renderTabContainer(commonTabSettings)}
      </div>
    </main>;
  };
}

ResultPageV3.contextType = AppContext;
