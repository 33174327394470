import React from "react";
import PropTypes from "prop-types";
import i18n from "../../i18n";
import SimpleTabDefault from "../result/SimpleTab";
import CreativeView from "../result/CreativeView";
import clientStorage from "../../utils/client-storage";
import {SvgSprite} from "../../components/SvgSprite";
import * as creativeUtils from "../../utils/creative";
import FileChooseButton from "../../components/FileChooseButton";
import {getCurrentLocale} from "../../i18n";
import {creativeGroups} from "../../photolab/groups";

class SimpleTab extends SimpleTabDefault {

  render() {
    const { creatives } = this.props;
    const canBeRefreshed = !!this.props.canBeRefreshed;
    const group = creatives.filter((creative) => creative.group === this.props.group);

    const creative = group.find((creative) => creative.is_selected);
    const creativeIsProcessing = !creative || creativeUtils.creativeIsProcessing(creative);
    const creativeIsFailed = creative && creativeUtils.creativeIsFailed(creative);

    const previewCreative = group.find((creative) => !creative.is_selected);
    const previewCreativeIsProcessed = previewCreative && creativeUtils.creativeIsProcessed(previewCreative);

    const isNarrow = creative
      && creative.file
      && creative.file.height > creative.file.width;

    const lang = getCurrentLocale().substring(0, 2).toLowerCase();

    // const refreshButtonText = (this.props.group === creativeGroups.CARTOON)
    //   ? i18n.t("collage__more_styles")
    //   : i18n.t("collage__refresh");
    const refreshButtonText = i18n.t("collage__more_styles");

    const isVisibleBeforePhotoToggler = this.props.site === "classic"
      && [creativeGroups.COLLAGE, creativeGroups.VECTOR].indexOf(this.props.group) === -1
      && window.clientConfig.splitGroupId <= 3
      && !window.clientConfig.isPro
      && (window.clientConfig.lang === "ja" || window.clientConfig.locale.indexOf("ja") >= 0 || window.clientConfig.locale.indexOf("jp") >= 0);

    return <div className="creative-holder-container">
      <div className={`creative-holder ${isNarrow ? "creative-holder--narrow" : ""}`}>
        {creativeIsProcessing && <div className="creative-holder-placeholder" />}

        {previewCreativeIsProcessed && <div className="wait-video">
          <img src={previewCreative.file.url} alt="Creative" />
        </div>}

        {creative && creative.file.url && <React.Fragment>
          <CreativeView
            creative={creative}
            onLoaded={this.props.onImageLoaded} />
          {window.clientConfig.isPro && <React.Fragment>
            <div className="watermark-container" hidden={this.props.site === "love"}>
              <div hidden={clientStorage.getProWatermarkTooltipIsHidden()}>
                <div className="tooltip">
                  <p dangerouslySetInnerHTML={{__html: i18n.t("toggle_watermark_tooltip__text")}} />
                </div>
                <div className="btn-remove-logo" hidden={clientStorage.getProWatermarkShouldBeRemoved()} onClick={this.props.onHideWatermarkButtonClick} />
              </div>
              <button
                  className="btn-logo-on"
                  hidden={!clientStorage.getProWatermarkShouldBeRemoved()}
                  onClick={this.props.onShowWatermarkButtonClick}>
                <span children={i18n.t("toggle_watermark_on")} />
              </button>
              <button
                  className="btn-logo-on"
                  hidden={clientStorage.getProWatermarkShouldBeRemoved() || !clientStorage.getProWatermarkTooltipIsHidden()}
                  onClick={this.props.onHideWatermarkButtonClick}>
                <span children={i18n.t("toggle_watermark_off")} />
              </button>
            </div>
            <div className="watermark-desktop-container" hidden={this.props.site === "love"}>
              <button
                  className="btn-logo-on"
                  hidden={!clientStorage.getProWatermarkShouldBeRemoved()}
                  onClick={this.props.onShowWatermarkButtonClick}>
                <SvgSprite viewBox="0 0 32 32" icon="icon-arrow-logo-on" />
                <span children={i18n.t("toggle_watermark_on_desktop")} />
              </button>
              <button
                  className="btn-logo-off"
                  hidden={clientStorage.getProWatermarkShouldBeRemoved() || !clientStorage.getProWatermarkTooltipIsHidden()}
                  onClick={this.props.onHideWatermarkButtonClick}>
                <SvgSprite viewBox="0 0 32 32" icon="icon-arrow-logo-off" />
                <span children={i18n.t("toggle_watermark_off_desktop")} />
              </button>
            </div>
          </React.Fragment>}

          <div className="watermark-container watermark-container-ja" hidden={!isVisibleBeforePhotoToggler}>
            <button
                className="btn-logo-on"
                hidden={!clientStorage.getBeforePhotoShouldBeRemoved()}
                onClick={this.props.onShowBeforePhotoButtonClick}>
              <span children={"オリジナルの写真を見せる"} />
            </button>
            <button
                className="btn-logo-on"
                hidden={clientStorage.getBeforePhotoShouldBeRemoved()}
                onClick={this.props.onHideBeforePhotoButtonClick}>
              <span children={"オリジナルの写真を隠す"} />
            </button>
          </div>

          <button
            className="btn-upload-foto-result"
            onClick={(e) => this.props.onDownloadButtonClick(e, creative, this.props.withHD)}>
              <SvgSprite className="download-icon" viewBox="0 0 228 228" icon={`btn-download-lang-${lang}`} />
            <div className="icon-container">
              <SvgSprite viewBox="0 0 24 24" icon="icon-download" />
            </div>
          </button>
          <button
            hidden={!canBeRefreshed}
            className="btn-refresh-foto-desktop btn-refresh-foto-desktop-v1"
            onClick={() => this.props.onRefreshButtonClick(this.props.group)}>
            <SvgSprite viewBox="0 0 24 24" icon="icon-refresh" />
            <span children={refreshButtonText} />
          </button>
        </React.Fragment>}

        {creative && creativeIsFailed && <div className="creative-error-message-container">
          <div className="creative-error-message">
            <p>{i18n.t("creative_failed_message")}</p>

            <button
              className="btn-retry"
              onClick={() => this.props.onRetryButtonClick(creative)}>
              {i18n.t("creative_failed_retry_btn")}
            </button>
          </div>
        </div>}

        <div className="loader-roller-wrapper">
          <div className="loader-roller loader-roller1">
            <div className="roller" />
            <div className="roller" />
          </div>
          <div className="loader-roller loader-roller2">
            <div className="roller" />
            <div className="roller" />
          </div>
          <div className="loader-roller loader-roller3">
            <div className="roller" />
            <div className="roller" />
          </div>
        </div>
        <div className="square square-big" />
        <div className="square square-med" />
        <div className="square square-small" />
      </div>

      <div className="btns-container" hidden={creativeIsProcessing || creativeIsFailed}>
        <FileChooseButton
          onFileSelected={this.props.onFileSelected}
          className="btn-update btn-update-big">
          <SvgSprite viewBox="0 0 24 24" icon="icon-magic" />
          <span children={i18n.t("try_another_photo")} />
        </FileChooseButton>
        <button
            className="btn-refresh-foto-desktop btn-refresh-foto-desktop-v2"
            disabled={!canBeRefreshed}
            onClick={() => this.props.onRefreshButtonClick(this.props.group)}>
            <SvgSprite viewBox="0 0 24 24" icon="icon-refresh" />
            <span children={refreshButtonText} />
          </button>
        <button
          className="btn-upload-foto"
          children={i18n.t("collage__download")}
          onClick={(e) => this.props.onDownloadButtonClick(e, creative, this.props.withHD)}>
          <div className="icon-container">
            <SvgSprite viewBox="0 0 24 24" icon="icon-download" />
          </div>
          <span children={i18n.t("collage__download")} />
        </button>
        {canBeRefreshed ? <button
          className="btn-update btn-refresh-foto"
          onClick={() => this.props.onRefreshButtonClick(this.props.group)}>
          <SvgSprite viewBox="0 0 24 24" icon="icon-refresh-v2" />
          <span children={refreshButtonText} />
        </button> : <div className="btn-spacer" />}
        <SvgSprite className="line-result-mob" viewBox="0 0 414 390" icon="line-result-mob" />
      </div>    
    </div>;
  }
}

SimpleTab.propTypes = {
  ...SimpleTab.propTypes,
  onFileSelected: PropTypes.func.isRequired,
};

export default SimpleTab;
