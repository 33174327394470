import * as photolabApi from "./api";
import * as api from "../utils/api";
import * as Sentry from "@sentry/react";
import PhotolabTaskBuilder from "./PhotolabTaskBuilder";
import PhotolabTaskCollageMethod from "./PhotolabTaskCollageMethod";
import PhotolabTaskImageUrl from "./PhotolabTaskImageUrl";
import {creativeGroups} from "./groups";
import {PhotolabResponseError} from "./api";
import {PhotolabResponseParseError} from "./api";
import {ApiResponseError} from "../utils/api";
import Creative from "./Creative";
import {hitEvent, hits, logEvent, userEvents} from "../utils/log";
import {bodies} from "./bodies";

export const handlersNames = {
  CROP: "crop",
  SIMPLE: "simple",
  SIMPLE2: "simple2",
  SIMPLE3: "simple3",
  CUPID: "cupid",
  COLLAGE: "collage",
  VECTOR: "vector",
  CARTOON_ANIM: "cartoon_anim",
  CARTOON_VECTOR_BODY: "cartoon_vector_body",
};

export const handlersMap = {
  [handlersNames.CROP]: cropHandler,
  [handlersNames.SIMPLE]: simpleHandler,
  [handlersNames.SIMPLE2]: simple2Handler,
  [handlersNames.SIMPLE3]: simple3Handler,
  [handlersNames.CUPID]: cupidHandler,
  [handlersNames.COLLAGE]: collageHandler,
  [handlersNames.VECTOR]: vectorHandler,
  [handlersNames.CARTOON_ANIM]: cartoonAnimHandler,
  [handlersNames.CARTOON_VECTOR_BODY]: cartoonVectorBodyHandler,
};

export function getHandlerByName(name) {
  return handlersMap[name] || null;
}

function safePromisify(value) {
  return value ? Promise.resolve(value) : null;
}

function promisifyCreativeTask(creative, taskName) {
  return safePromisify(creative.getTask(taskName));
}

function waitPhotolabTaskHelper(creative, taskName, taskResult, timeout, interval = 2000) {
  if (taskResult.resultUrl) {
    return Promise.resolve(taskResult);
  } else {
    const taskStartedAt = Date.now();
    const taskTimerId30Sec = setTimeout(() => {
      logLongProcessingTask(creative, taskName, taskResult);
      hitEvent(hits.PROCESSING_TASK_GREATER_THEN_30_SEC);
    }, 30000);
    const taskTimerId60Sec = setTimeout(() => {
      hitEvent(hits.PROCESSING_TASK_GREATER_THEN_60_SEC)
    }, 60000);

    return photolabApi.photolabWaitTask(taskResult.requestId, timeout, interval)
        .then((result) => {
          clearTimeout(taskTimerId30Sec);
          clearTimeout(taskTimerId60Sec);
          logProcessedTask(creative, taskName, taskResult, result, taskStartedAt);

          return result;
        })
        .catch((error) => {
          clearTimeout(taskTimerId30Sec);
          clearTimeout(taskTimerId60Sec);
          logFailedTask(creative, taskName, taskResult, error, taskStartedAt);

          throw error;
        });
  }
}

function waitTaskHelper(creative, taskName, taskResult, timeout = 0, interval = 1000) {
  const taskStartedAt = Date.now();
  const taskTimerId30Sec = setTimeout(() => {
    logLongProcessingTask(creative, taskName, taskResult);
    hitEvent(hits.PROCESSING_TASK_GREATER_THEN_30_SEC);
  }, 30000);
  const taskTimerId60Sec = setTimeout(() => {
    hitEvent(hits.PROCESSING_TASK_GREATER_THEN_60_SEC)
  }, 60000);

  return waitTask(taskResult.id, timeout, interval)
    .then((result) => {
      clearTimeout(taskTimerId30Sec);
      clearTimeout(taskTimerId60Sec);
      logProcessedTask(creative, taskName, taskResult, result, taskStartedAt);

      return result;
    })
    .catch((error) => {
      clearTimeout(taskTimerId30Sec);
      clearTimeout(taskTimerId60Sec);
      logFailedTask(creative, taskName, taskResult, error, taskStartedAt);

      throw error;
    });
}

function defaultHandlerCatch(creative, reject) {
  return (err) => {
    console.error(err);

    if (!(err instanceof PhotolabResponseError || err instanceof HandlerCancelError)) {
      Sentry.captureException(err);
    }

    const type = "internal";
    const errorState = {
      type,
      name: err.name,
      code: err.code,
      message: err.message,
    };

    if (err instanceof PhotolabResponseError || err instanceof PhotolabResponseParseError) {
      errorState.type = "photolab";
    } else if (err instanceof ApiResponseError) {
      errorState.type = "api";
    } else if (err instanceof ApiTaskError) {
      errorState.type = "api_task";
    } else if (err instanceof HandlerCancelError) {
      errorState.type = "handler_cancel";
    }

    creative.markAsFailed(errorState);

    reject(creative);
  };
}

class ApiTaskError extends Error {
  constructor(task) {
    super();
    this.name = "ApiTaskError";
    this.code = -1;
    this.message = task.result && task.result.reason;
  }
}

class HandlerCancelError extends Error {
  constructor(message) {
    super();
    this.name = "HandlerCancelError";
    this.code = -1;
    this.message = message;
  }
}

function waitTask(taskId, timeout = 0, interval = 1000, requestsAmount = 0) {
  requestsAmount++;

  function _call(resolve, reject) {
    api.fetchTask(taskId).then((task) => {
      if (task.status === 1) {
        task.getResultRequestsAmount = requestsAmount;
        resolve(task);
      } else if (task.status === -1) {
        throw new ApiTaskError(task);
      } else {
        setTimeout(() => {
          waitTask(taskId, 0, interval).then(resolve).catch(reject);
        }, interval || 1000);
      }
    }).catch((error) => {
      error.getResultRequestsAmount = requestsAmount;
      reject(error);
    });
  }

  return new Promise((resolve, reject) => {
    if (timeout === 0) {
      _call(resolve, reject);
    } else {
      setTimeout(() => _call(resolve, reject), timeout);
    }
  });
}

/**
 * @param {Processing} processing
 * @returns {Promise<Creative>}
 */
function waitCrop(processing) {
  return waitCreative(processing.getSelectedCreativeInGroup(creativeGroups.CROP));
}

/**
 * @param {Creative} creative
 * @returns {Promise<Creative>}
 */
function waitCreative(creative) {
  return new Promise((resolve) => {
    if (creative.isProcessed || creative.isFailed) {
      resolve(creative);
    } else {
      setTimeout(() => {
        waitCreative(creative).then(resolve);
      }, 250);
    }
  });
}

/**
 * @param {Creative[]} creatives
 * @returns {Promise<Creative>}
 */
function waitCreatives(creatives) {
  return new Promise((resolve) => {
    const finishedCreatives = creatives.filter((c) => c.isProcessed || c.isFailed);

    if (finishedCreatives.length === creatives.length) {
      resolve(creatives);
    } else {
      setTimeout(() => {
        waitCreatives(creatives).then(resolve);
      }, 250);
    }
  });
}

function logLongProcessingTask(creative, taskName, taskResult) {
  logEvent(userEvents.PROCESSING_TASK_PROCESSING, {
    group: creative.group,
    template_id: creative.templateId,
    source_file_id: creative.fileId,
    task_name: taskName,
    task_request_id: taskResult.requestId || taskResult.id || "",
  });
}

function logProcessedTask(creative, taskName, addTaskResult, taskResult, taskStartedAt) {
  const processingTime = Date.now() - taskStartedAt;

  logEvent(userEvents.PROCESSING_TASK_PROCESSED, {
    group: creative.group,
    template_id: creative.templateId,
    task_name: taskName,
    processing_time: processingTime,
    source_file_id: creative.fileId,
    task_finish_at: Date.now(),
    task_request_id: addTaskResult.requestId || addTaskResult.id || "",
    task_enqueue_at: addTaskResult.addTaskRequestAt,
    task_enqueued_at: addTaskResult.addTaskResponseAt,
    task_get_result_requests: taskResult.getResultRequestsAmount,
  });

  if (processingTime >= 60000) {
    hitEvent(hits.PROCESSING_TASK_GREATER_THEN_60_SEC);
  } else if (processingTime >= 30000) {
    hitEvent(hits.PROCESSING_TASK_GREATER_THEN_30_SEC);
  }
}

function logFailedTask(creative, taskName, addTaskResult, taskResult, taskStartedAt) {
  const processingTime = Date.now() - taskStartedAt;

  logEvent(userEvents.PROCESSING_TASK_FAILED, {
    group: creative.group,
    template_id: creative.templateId,
    task_name: taskName,
    processing_time: processingTime,
    source_file_id: creative.fileId,
    task_finish_at: Date.now(),
    task_request_id: addTaskResult.requestId || addTaskResult.id || "",
    task_enqueue_at: addTaskResult.addTaskRequestAt,
    task_enqueued_at: addTaskResult.addTaskResponseAt,
    task_get_result_requests: taskResult.getResultRequestsAmount,
    task_error_code: taskResult.code || 0,
  });

  if (processingTime >= 60000) {
    hitEvent(hits.PROCESSING_TASK_GREATER_THEN_60_SEC);
  } else if (processingTime >= 30000) {
    hitEvent(hits.PROCESSING_TASK_GREATER_THEN_30_SEC);
  }
}

/**
 * @param {Processing} processing
 * @param {Creative} creative
 */
export function cropHandler(processing, creative) {
  const cropTaskName = "crop";

  function createTask() {
    return new PhotolabTaskBuilder()
      .setLanguage(processing.language)
      .addMethod(new PhotolabTaskCollageMethod(creative.templateId))
      .addImage(new PhotolabTaskImageUrl(processing.file.url))
      .build();
  }

  return new Promise((resolve, reject) => {
    const cropTask = promisifyCreativeTask(creative, cropTaskName) || photolabApi.photolabAddTask(createTask());

    cropTask
      .then((cropTaskResult) => waitPhotolabTaskHelper(creative, cropTaskName, cropTaskResult, 3000))
      .then((cropTaskResult) => {
        creative.setTask(cropTaskName, cropTaskResult);
        creative.markAsProcessed(cropTaskResult.resultUrl);

        resolve(creative);
      })
      .catch(defaultHandlerCatch(creative, reject));
  });
}

/**
 * @param {Processing} processing
 * @param {Creative} creative
 */
export function simpleHandler(processing, creative) {
  const templateTaskName = "template";
  const layoutTaskName = "layout";
  const storeTaskName = "store";

  function createTemplateTask() {
    return new PhotolabTaskBuilder()
      .setLanguage(processing.language)
      .addMethod(new PhotolabTaskCollageMethod(creative.templateId))
      .addImage(new PhotolabTaskImageUrl(processing.file.url))
      .build();
  }

  function createLayoutTask(templateTask) {
    const cropCreative = processing.getSelectedCreativeInGroup(creativeGroups.CROP);
    if (!cropCreative || cropCreative.isFailed) {
      throw new HandlerCancelError("The crop creative is failed");
    }

    return new PhotolabTaskBuilder()
      .setLanguage(processing.language)
      .addMethod(new PhotolabTaskCollageMethod(4174))
      .addImage(new PhotolabTaskImageUrl(templateTask.resultUrl))
      .addImage(new PhotolabTaskImageUrl(cropCreative.resultUrl))
      .build();
  }

  return new Promise((resolve, reject) => {
    const templateTaskResult = promisifyCreativeTask(creative, templateTaskName)
      || photolabApi.photolabAddTask(createTemplateTask());

    templateTaskResult
      .then((templateTaskResult) => waitPhotolabTaskHelper(creative, templateTaskName, templateTaskResult, 3000))
      .then((templateTaskResult) => {
        creative.setTask(templateTaskName, templateTaskResult);
        creative.setFile("template", templateTaskResult.resultUrl);
      })
      .then(() => waitCrop(processing))
      .then(() => {
        return promisifyCreativeTask(creative, layoutTaskName)
          || photolabApi.photolabAddTask(createLayoutTask(creative.getTask(templateTaskName)));
      })
      .then((layoutTaskResult) => waitPhotolabTaskHelper(creative, layoutTaskName, layoutTaskResult, 3000))
      .then((layoutTaskResult) => {
        creative.setTask(layoutTaskName, layoutTaskResult);
        creative.setFile("before_watermark", layoutTaskResult.resultUrl);

        return api.enqueueCreativeStoreTask(layoutTaskResult.resultUrl, processing.watermarkName);
      })
      .then((storeTaskResult) => waitTaskHelper(creative, storeTaskName, storeTaskResult, 1000))
      .then((storeTaskResult) => {
        creative.setTask(storeTaskName, storeTaskResult);
        creative.markAsProcessed(storeTaskResult.result.url);

        resolve(creative);
      })
      .catch(defaultHandlerCatch(creative, reject));
  });
}

/**
 * @param {Processing} processing
 * @param {Creative} creative
 */
export function simple2Handler(processing, creative) {
  const templateTaskName = "template";
  const storeTaskName = "store";

  function createTemplateTask() {
    return new PhotolabTaskBuilder()
      .setLanguage(processing.language)
      .addMethod(new PhotolabTaskCollageMethod(creative.templateId))
      .addImage(new PhotolabTaskImageUrl(processing.file.url))
      .build();
  }

  return new Promise((resolve, reject) => {
    const templateTaskResult = promisifyCreativeTask(creative, templateTaskName)
      || photolabApi.photolabAddTask(createTemplateTask());

    templateTaskResult
      .then((templateTaskResult) => waitPhotolabTaskHelper(creative, templateTaskName, templateTaskResult, 3000))
      .then((templateTaskResult) => {
        creative.setTask(templateTaskName, templateTaskResult);
        creative.setFile("template", templateTaskResult.resultUrl);
        creative.setFile("before_watermark", templateTaskResult.resultUrl);

        return api.enqueueCreativeStoreTask(templateTaskResult.resultUrl, processing.watermarkName);
      })
      .then((storeTaskResult) => waitTaskHelper(creative, storeTaskName, storeTaskResult, 1000))
      .then((storeTaskResult) => {
        creative.setTask(storeTaskName, storeTaskResult);
        creative.markAsProcessed(storeTaskResult.result.url);

        resolve(creative);
      })
      .catch(defaultHandlerCatch(creative, reject));
  });
}

/**
 * @param {Processing} processing
 * @param {Creative} creative
 */
export function simple3Handler(processing, creative) {
  const templateTaskName = "template";
  const layoutTaskName = "layout";
  const colorTaskName = "color_correction";
  const storeTaskName = "store";

  function createTemplateTask() {
    return new PhotolabTaskBuilder()
      .setLanguage(processing.language)
      .addMethod(new PhotolabTaskCollageMethod(creative.templateId))
      .addImage(new PhotolabTaskImageUrl(processing.file.url))
      .build();
  }

  function createColorTask(templateTask) {
    return new PhotolabTaskBuilder()
      .setLanguage(processing.language)
      .addMethod(new PhotolabTaskCollageMethod(5273))
      .addImage(new PhotolabTaskImageUrl(templateTask.resultUrl))
      .build();
  }

  function createLayoutTask(templateTask) {
    const cropCreative = processing.getSelectedCreativeInGroup(creativeGroups.CROP);
    if (!cropCreative || cropCreative.isFailed) {
      throw new HandlerCancelError("The crop creative is failed");
    }

    return new PhotolabTaskBuilder()
      .setLanguage(processing.language)
      .addMethod(new PhotolabTaskCollageMethod(4174))
      .addImage(new PhotolabTaskImageUrl(templateTask.resultUrl))
      .addImage(new PhotolabTaskImageUrl(cropCreative.resultUrl))
      .build();
  }

  return new Promise((resolve, reject) => {
    const templateTaskResult = promisifyCreativeTask(creative, templateTaskName)
      || photolabApi.photolabAddTask(createTemplateTask());

    templateTaskResult
      .then((templateTaskResult) => waitPhotolabTaskHelper(creative, templateTaskName, templateTaskResult, 3000))
      .then((templateTaskResult) => {
        creative.setTask(templateTaskName, templateTaskResult);
        return promisifyCreativeTask(creative, colorTaskName)
          || photolabApi.photolabAddTask(createColorTask(templateTaskResult));
      })
      .then((colorTaskResult) => waitPhotolabTaskHelper(creative, colorTaskName, colorTaskResult, 2000))
      .then((colorTaskResult) => {
        creative.setTask(colorTaskName, colorTaskResult);
        creative.setFile("template", colorTaskResult.resultUrl);
      })
      .then(() => waitCrop(processing))
      .then(() => {
        return promisifyCreativeTask(creative, layoutTaskName)
          || photolabApi.photolabAddTask(createLayoutTask(creative.getTask(colorTaskName)));
      })
      .then((layoutTaskResult) => waitPhotolabTaskHelper(creative, layoutTaskName, layoutTaskResult, 3000))
      .then((layoutTaskResult) => {
        creative.setTask(layoutTaskName, layoutTaskResult);
        creative.setFile("before_watermark", layoutTaskResult.resultUrl);

        return api.enqueueCreativeStoreTask(layoutTaskResult.resultUrl, processing.watermarkName);
      })
      .then((storeTaskResult) => waitTaskHelper(creative, storeTaskName, storeTaskResult, 1000))
      .then((storeTaskResult) => {
        creative.setTask(storeTaskName, storeTaskResult);
        creative.markAsProcessed(storeTaskResult.result.url);

        resolve(creative);
      })
      .catch(defaultHandlerCatch(creative, reject));
  });
}

/**
 * @param {Processing} processing
 * @param {Creative} creative
 */
export function cupidHandler(processing, creative) {
  const templateTaskName = "template";
  const storeTaskName = "store";

  function createTemplateTask() {
    return new PhotolabTaskBuilder()
      .setLanguage(processing.language)
      .addMethod(new PhotolabTaskCollageMethod(creative.templateId))
      .addImage(new PhotolabTaskImageUrl(processing.file.url))
      .build();
  }

  return new Promise((resolve, reject) => {
    const templateTaskResult = promisifyCreativeTask(creative, templateTaskName)
      || photolabApi.photolabAddTask(createTemplateTask());

    templateTaskResult
      .then((templateTaskResult) => waitPhotolabTaskHelper(creative, templateTaskName, templateTaskResult, 3000))
      .then((templateTaskResult) => {
        creative.setTask(templateTaskName, templateTaskResult);
        return api.enqueueCreativeStoreTask(templateTaskResult.resultUrl, processing.watermarkName);
      })
      .then((storeTaskResult) => waitTaskHelper(creative, storeTaskName, storeTaskResult, 1000))
      .then((storeTaskResult) => {
        creative.setTask(storeTaskName, storeTaskResult);
        creative.markAsProcessed(storeTaskResult.result.url);
        resolve(creative);
      })
      .catch(defaultHandlerCatch(creative, reject));
  });
}

/**
 * @param {Processing} processing
 * @param {Creative} creative
 */
export function collageHandler(processing, creative) {
  const collageTaskName = "collage";
  const storeTaskName = "store";

  function createCollageTask(creatives) {
    if (creatives.length < 4) {
      throw new HandlerCancelError("Collage needs 4 creatives, " + creatives.length + " received.");
    }

    const failedCreative = creatives.find((creative) => creative.isFailed);
    if (failedCreative) {
      throw new HandlerCancelError("One of the collage creatives is failed");
    }

    const cropCreative = processing.getSelectedCreativeInGroup(creativeGroups.CROP);
    if (!cropCreative || cropCreative.isFailed) {
      throw new HandlerCancelError("The crop creative is failed");
    }

    const builder = new PhotolabTaskBuilder();
    builder.setLanguage(processing.language);

    builder.addMethod(new PhotolabTaskCollageMethod(creative.templateId));
    creatives.forEach((creative) => {
      builder.addImage(new PhotolabTaskImageUrl(creative.getFile("template") || creative.resultUrl));
    });

    builder.addImage(new PhotolabTaskImageUrl(cropCreative.resultUrl));

    return builder.build();
  }

  return new Promise((resolve, reject) => {
    let creatives;
    let collageHash = creative.getExtra(Creative.EXTRA_COLLAGE_HASH);

    if (collageHash) {
      creatives = collageHash.split(";")
        .map((cs) => cs.split(":"))
        .map((cd) => processing.creatives.find((c) => c.group === cd[0] && c.templateId === parseInt(cd[1])));
    } else {
      const groups = creative.getExtra(Creative.EXTRA_COLLAGE_GROUPS);
      creatives = groups.map((group) => processing.getSelectedCreativeInGroup(group));
      collageHash = creatives.map((c) => `${c.group}:${c.templateId}`).join(";");
      creative.setExtra(Creative.EXTRA_COLLAGE_HASH, collageHash);
    }

    waitCreatives(creatives)
      .then((creatives) => {
        return promisifyCreativeTask(creative, collageTaskName)
          || photolabApi.photolabAddTask(createCollageTask(creatives));
      })
      .then((collageTaskResult) => waitPhotolabTaskHelper(creative, collageTaskName, collageTaskResult, 3000))
      .then((collageTaskResult) => {
        creative.setTask(collageTaskName, collageTaskResult);
        creative.setFile("template", collageTaskResult.resultUrl);
        creative.setFile("before_watermark", collageTaskResult.resultUrl);

        return api.enqueueCreativeStoreTask(collageTaskResult.resultUrl, processing.watermarkName);
      })
      .then((storeTaskResult) => waitTaskHelper(creative, storeTaskName, storeTaskResult, 1000))
      .then((storeTaskResult) => {
        creative.setTask(storeTaskName, storeTaskResult);
        creative.markAsProcessed(storeTaskResult.result.url);

        resolve(creative);
      })
      .catch(defaultHandlerCatch(creative, reject));
  });
}

/**
 * @param {Processing} processing
 * @param {Creative} creative
 */
export function vectorHandler(processing, creative) {
  const templateTaskName = "template";
  const backgroundTaskName = "background";
  const storeTaskName = "store";

  function createTemplateTask(cropCreative) {
    if (cropCreative.isFailed) {
      throw new HandlerCancelError("The crop creative is failed");
    }

    return new PhotolabTaskBuilder()
      .setLanguage(processing.language)
      .addMethod(new PhotolabTaskCollageMethod(creative.templateId))
      .addImage(new PhotolabTaskImageUrl(cropCreative.resultUrl))
      .build();
  }

  function createBackgroundTask(templateTask) {
    return new PhotolabTaskBuilder()
      .setLanguage(processing.language)
      .addMethod(new PhotolabTaskCollageMethod(4081))
      .addImage(new PhotolabTaskImageUrl(templateTask.resultUrl))
      .build();
  }

  return new Promise((resolve, reject) => {
    waitCrop(processing)
      .then((creative) => {
        return promisifyCreativeTask(creative, templateTaskName)
          || photolabApi.photolabAddTask(createTemplateTask(creative));
      })
      .then((templateTaskResult) => waitPhotolabTaskHelper(creative, templateTaskName, templateTaskResult, 5000))
      .then((templateTaskResult) => {
        creative.setTask(templateTaskName, templateTaskResult);

        return promisifyCreativeTask(creative, backgroundTaskName)
          || photolabApi.photolabAddTask(createBackgroundTask(templateTaskResult));
      })
      .then((backgroundTaskResult) => waitPhotolabTaskHelper(creative, backgroundTaskName, backgroundTaskResult, 3000))
      .then((backgroundTaskResult) => {
        creative.setTask(backgroundTaskName, backgroundTaskResult);
        creative.setFile("template", backgroundTaskResult.resultUrl);
        creative.setFile("before_watermark", backgroundTaskResult.resultUrl);

        return api.enqueueCreativeStoreTask(backgroundTaskResult.resultUrl, processing.watermarkName)
      })
      .then((storeTaskResult) => waitTaskHelper(creative, storeTaskName, storeTaskResult, 1000))
      .then((storeTaskResult) => {
        creative.setTask(storeTaskName, storeTaskResult);
        creative.markAsProcessed(storeTaskResult.result.url);

        resolve(creative);
      })
      .catch(defaultHandlerCatch(creative, reject))
  });
}

/**
 * @param {Processing} processing
 * @param {Creative} creative
 */
export function cartoonAnimHandler(processing, creative) {
  const templateTaskName = "template";
  const storeTaskName = "store";

  function createTemplateTask() {
    return new PhotolabTaskBuilder()
      .setLanguage(processing.language)
      .addMethod(new PhotolabTaskCollageMethod(creative.templateId))
      .addImage(new PhotolabTaskImageUrl(processing.file.url))
      .build();
  }

  return new Promise((resolve, reject) => {
    const templateTaskResult = promisifyCreativeTask(creative, templateTaskName)
      || photolabApi.photolabAddTask(createTemplateTask());

    templateTaskResult
      .then((templateTaskResult) => waitPhotolabTaskHelper(creative, templateTaskName, templateTaskResult, 5000))
      .then((templateTaskResult) => {
        creative.setTask(templateTaskName, templateTaskResult);

        return api.enqueueCreativeVideoStoreTask(templateTaskResult.resultUrl, processing.watermarkName);
      })
      .then((storeTaskResult) => waitTaskHelper(creative, storeTaskName, storeTaskResult, 1000))
      .then((storeTaskResult) => {
        creative.setTask(storeTaskName, storeTaskResult);
        creative.markAsProcessed(storeTaskResult.result.url);

        resolve(creative);
      })
      .catch(defaultHandlerCatch(creative, reject));
  });
}

/**
 * @param {Processing} processing
 * @param {Creative} creative
 */
export function cartoonVectorBodyHandler(processing, creative) {
  const genderTaskName = "gender";
  const headTaskName = "head";
  const bodyTaskName = "body";
  const storeTaskName = "store";

  function createGenderTask() {
    return new PhotolabTaskBuilder()
      .setLanguage(processing.language)
      .addMethod(new PhotolabTaskCollageMethod(PhotolabTaskCollageMethod.ID_GENDER_CLASSIFIER))
      .addImage(new PhotolabTaskImageUrl(processing.file.url))
      .build();
  }

  function createHeadTask() {
    return new PhotolabTaskBuilder()
      .setLanguage(processing.language)
      .addMethod(new PhotolabTaskCollageMethod(creative.templateId))
      .addImage(new PhotolabTaskImageUrl(processing.file.url))
      .build();
  }

  function createBodyTask(templateTask) {
    if (!creative.hasExtra(Creative.EXTRA_BODY_TEMPLATE_ID)) {
      const genderTask = creative.getTask(genderTaskName);
      const gender = genderTask && genderTask.gender;
      const selectedBodies = bodies.slice().shuffle().filter((body) => {
        return gender ? (gender === body.gender) : true;
      });

      creative.setExtra(Creative.EXTRA_BODY_TEMPLATE_ID, selectedBodies.random().id);
    }

    return new PhotolabTaskBuilder()
      .setLanguage(processing.language)
      .addMethod(new PhotolabTaskCollageMethod(creative.getExtra(Creative.EXTRA_BODY_TEMPLATE_ID)))
      .addImage(new PhotolabTaskImageUrl(templateTask.resultUrl))
      .build();
  }

  return new Promise((resolve, reject) => {
    (promisifyCreativeTask(creative, genderTaskName) || photolabApi.photolabAddTask(createGenderTask()))
      .then((genderTaskResult) => waitPhotolabTaskHelper(creative, genderTaskName, genderTaskResult, 1000))
      .then((genderTaskResult) => creative.setTask(genderTaskName, genderTaskResult))
      .catch((err) => {
        console.error(err);
        /* ignore */
      });

    const headTaskResult = promisifyCreativeTask(creative, headTaskName)
      || photolabApi.photolabAddTask(createHeadTask());

    headTaskResult
      .then((headTaskResult) => waitPhotolabTaskHelper(creative, headTaskName, headTaskResult, 7000, 2000))
      .then((headTaskResult) => {
        creative.setTask(headTaskName, headTaskResult);

        return promisifyCreativeTask(creative, bodyTaskName)
          || photolabApi.photolabAddTask(createBodyTask(headTaskResult));
      })
      .then((bodyTaskResult) => waitPhotolabTaskHelper(creative, bodyTaskName, bodyTaskResult, 3000))
      .then((bodyTaskResult) => {
        creative.setTask(bodyTaskName, bodyTaskResult);
        creative.setFile("before_watermark", bodyTaskResult.resultUrl);

        return api.enqueueCreativeStoreTask(bodyTaskResult.resultUrl, processing.watermarkName);
      })
      .then((storeTaskResult) => waitTaskHelper(creative, storeTaskName, storeTaskResult, 1000))
      .then((storeTaskResult) => {
        creative.setTask(storeTaskName, storeTaskResult);
        creative.markAsProcessed(storeTaskResult.result.url);

        resolve(creative);
      })
      .catch(defaultHandlerCatch(creative, reject));
  });
}