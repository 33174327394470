import {getLocationQueryObject} from "./utils/text";
import clientStorage from "./utils/client-storage";
import {detect} from "detect-browser";
import {generateHash} from "./utils/text";
import {androidRequestParams, iosRequestParams} from "./utils/constants";

let query = getLocationQueryObject();
const platformInfo = detect();
const platformIsMobile = ["android", "android os", "ios"].indexOf(platformInfo.os.toLowerCase()) > -1;
let isWebview = typeof query["aid"] !== "undefined" || typeof query["vicman_unified_id"] !== "undefined";
let isWebviewMock = false;

if (isWebview) {
  clientStorage.setWebviewRequestParams(query);
}

if (!isWebview && window.location.hostname === process.env.REACT_APP_CDN_HOSTNAME) {
  let requestParams = clientStorage.getWebviewRequestParams();

  isWebview = true;
  isWebviewMock = true;

  if (Object.keys(requestParams).length === 0) {
    if (["ios"].indexOf(platformInfo.os.toLowerCase()) > -1) {
      requestParams = iosRequestParams;
      requestParams.vicman_unified_id = generateHash();
    } else {
      requestParams = androidRequestParams;
      requestParams.aid = generateHash();
    }

    clientStorage.setWebviewRequestParams(requestParams);
  }

  query = {...requestParams, ...query};
}

function detectLocale() {
  const langs = window.navigator.languages || [window.navigator.language] || ["en"];
  const chineseLike = langs.find((locale) => locale.toLowerCase().startsWith("zh"));
  return chineseLike || langs[0];
}

window.clientConfig = {
  platform: platformInfo,
  isWeb: !isWebview,
  isWebMobile: !isWebview && platformIsMobile,
  isWebMobileAndroid: platformInfo.os.toLowerCase() === "android" || platformInfo.os.toLowerCase() === "android os",
  isWebMobileIOS: platformInfo.os.toLowerCase() === "ios",
  isWebDesktop: !isWebview && !platformIsMobile,
  isWebview: isWebview,
  isWebviewAndroid: typeof query["aid"] !== "undefined",
  isWebviewIOS: typeof query["vicman_unified_id"] !== "undefined",
  isWebviewMock: isWebviewMock,
  isAnyMobile: isWebview || platformIsMobile,
  webviewParams: isWebview ? query : {},
  isPro: query["is_pro"] === "1",
  locale: query["locale"] || query["lang"] || detectLocale() || undefined,
  splitGroupId: undefined,
  splits: {},
  isChinaUser: false,
  isJapanUser: false,
  isUsaUser: false,
  isGreatBritainUser: false,
};

window.clientConfig.lang = window.clientConfig.locale
  ? window.clientConfig.locale.substring(0, 2).toLowerCase()
  : undefined;

// ---

window.clientConfig.isJapanUser = window.clientConfig.lang === "ja" || (window.clientConfig.isWebview && window.clientConfig.webviewParams.country === "JP");
window.clientConfig.isChinaUser = window.clientConfig.lang === "zh" || (window.clientConfig.isWebview && window.clientConfig.webviewParams.country === "CN");
window.clientConfig.isUsaUser = query["country"] === "us" || (window.clientConfig.isWebview && window.clientConfig.webviewParams.country === "US");
window.clientConfig.isGreatBritainUser = query["country"] === "gb" || (window.clientConfig.isWebview && window.clientConfig.webviewParams.country === "GB");

// ---

if (window.clientConfig.isWebview) {
  window.clientConfig.token = window.clientConfig.isWebviewAndroid
    ? `w:a:${query["aid"]}`
    : `w:i:${query["vicman_unified_id"]}`;
} else {
  const clientToken = clientStorage.getClientToken();
  if (clientToken) {
    window.clientConfig.token = clientToken;
  } else {
    const type = platformIsMobile ? "m" : "d";
    const hash = generateHash();

    window.clientConfig.token = `b:${type}:${hash}`;
    clientStorage.setClientToken(window.clientConfig.token);
  }
}

// ---

if (window.clientConfig.isWebview) {
  const aid = query["aid"] || query["vicman_unified_id"];
  const num = parseInt(aid.substring(aid.length - 2), 16);
  const group = Math.floor(num / (255 / 10)) + 1;

  window.clientConfig.splitGroupId = Math.min(group, 10); // fix for ff => 11 group
} else {
  const groupId = query["split_group"] || clientStorage.getSplitGroupId();
  if (groupId) {
    window.clientConfig.splitGroupId = parseInt(groupId);
  } else {
    window.clientConfig.splitGroupId = Math.floor(Math.random() * 10) + 1;
  }

  clientStorage.setSplitGroupId(window.clientConfig.splitGroupId);
}

// ---

window.appConfig = {
  designVersion: "v1",

  isDebug: process.env.NODE_ENV.startsWith("prod") === false
    || process.env.REACT_APP_DEBUG === "true"
    || query["vicman_debug"]
    || false,

  paths: {
    app: process.env.REACT_APP_APP_PATH,
    assets: process.env.REACT_APP_ASSETS_PATH,
    api: process.env.REACT_APP_API_PATH,
    apiUpload: process.env.REACT_APP_API_FILES_PATH,
    apiSign: process.env.REACT_APP_API_SIGN_PATH,
  },

  photolab: {
    appId: process.env.REACT_APP_PHOTOLAB_APP_ID,
    path: process.env.REACT_APP_PHOTOLAB_API_PATH,
  },

  amplitude: {
    isEnabled: process.env.REACT_APP_AMPLITUDE_ENABLED === "true",
    key: process.env.REACT_APP_AMPLITUDE_KEY,
  },

  analytics: {
    isEnabled: process.env.REACT_APP_ANALYTICS_ENABLED === "true",
    endpoint: process.env.REACT_APP_ANALYTICS_ENDPOINT,
  },

  hits: {
    isEnabled: process.env.REACT_APP_HITS_ENABLED === "true",
    endpoint: process.env.REACT_APP_HITS_ENDPOINT,
  },

  facebook: {
    appId: 202248336556242,
  },

  sentry: {
    isEnabled: process.env.REACT_APP_SENTRY_ENABLED === "true",
    dsn: process.env.REACT_APP_SENTRY_DSN || "",
  },

  googleAnalytics: {
    isEnabled: (process.env.REACT_APP_GOOGLE_ANALYTICS_ENABLED === "true") && window.clientConfig.isWeb,
    trackerId: process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKER_ID,
  },

  nativeAds: {
    isEnabled: process.env.REACT_APP_NATIVE_ADS_IS_ENABLED === "true",
  },

  webAds: {
    isEnabled: window.webAdsIsEnabled,
  },

  webview: {
    uploadEnabledPercent: parseFloat(process.env.REACT_APP_WEBVIEW_UPLOAD_ENABLED),
    uploadDisabledCountries: (process.env.REACT_APP_WEBVIEW_UPLOAD_DISABLED_COUNTRIES || "")
      .split(",")
      .map(c => c.toLowerCase()),
    rateAppDialogPercent: parseFloat(process.env.REACT_APP_WEBVIEW_RATEAPP_DIALOG_PERCENT),
    clonesDialogPercent: parseFloat(process.env.REACT_APP_WEBVIEW_CLONES_DIALOG_PERCENT),
  },
};
