import React from "react";
import i18n from "../i18n";

const LOADING_TEXT_INTERVAL = 3000;

const textsDefault = [
  // i18n.t("processing_text_1"),
  i18n.t("processing_text_2"),
  // i18n.t("processing_text_3"),
  i18n.t("processing_text_4"),
  i18n.t("processing_text_5"),
  i18n.t("processing_text_6"),
  // i18n.t("processing_text_7"),
];

const textsLove = [
  i18n.t("love_processing_text_1"),
  i18n.t("love_processing_text_2"),
  i18n.t("love_processing_text_3"),
  i18n.t("love_processing_text_4"),
  i18n.t("love_processing_text_5"),
  i18n.t("love_processing_text_6"),
  i18n.t("love_processing_text_7"),
  i18n.t("love_processing_text_8"),
  i18n.t("love_processing_text_9"),
  i18n.t("love_processing_text_10"),
];

export default class Loading extends React.Component {

  constructor(props) {
    super(props);

    this.texts = props.site === "love" ? textsLove : textsDefault;

    this.state = {
      imageIsLoaded: false,
      textIndex: Math.floor(Math.random() * (this.texts.length - 1)),
    };

    this.image = new Image();
    this.image.onload = () => this.setState({imageIsLoaded: true});

    this.updateTextIndexTimer = null;
  }

  componentDidMount() {
    this.updateTextIndexTimer = setInterval(this.updateTextIndex, LOADING_TEXT_INTERVAL);

    if (this.props.image) {
      this.image.src = this.props.image;
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.image !== this.props.image) {
      this.setState({imageIsLoaded: false}, () => {
        this.image.src = this.props.image;
      });
    }
  }

  componentWillUnmount() {
    this.image.onload = () => {};
    clearInterval(this.updateTextIndexTimer);
  }

  updateTextIndex = () => {
    if (this.state.textIndex === this.texts.length - 1) {
      this.setState({textIndex: 0});
    } else {
      this.setState({textIndex: this.state.textIndex + 1});
    }
  };

  render() {
    const textString = this.props.text
    || (this.props.withProcessingText ? this.texts[this.state.textIndex] : "&nbsp;");

    return <main className="loader">
      <div className="spinner-container">
        <div className="triple-spinner" />
        {/*<img */}
        {/*  srcSet={`${assetUrl("assets/img/icons/img-pl@2x.jpg")} 2x, ${assetUrl("assets/img/icons/img-pl@3x.jpg")} 3x`}*/}
        {/*  src={assetUrl("assets/img/icons/img-pl.jpg")}*/}
        {/*  alt="logo" />*/}

        {this.props.image && this.state.imageIsLoaded && <img
          className="avatar-image"
          src={this.props.image}
          alt="" />}
      </div>
      <p dangerouslySetInnerHTML={{__html: textString}} />
    </main>;
  }
}
