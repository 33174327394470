import React from 'react';
import Slider from "react-slick";
import {SvgSprite} from "../components/SvgSprite";
import {assetUrl} from "../utils/etc";
import i18n from "../i18n";

const sliderSettings = {
  // autoplay: true,
  // autoplaySpeed: 3000,
  // dots: true,
  arrows: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  centerMode: true,
  centerPadding: '0',
  responsive: [{
    breakpoint: 440,
    settings: {centerPadding: '70px'}
  }, {
    breakpoint: 380,
    settings: {centerPadding: '60px'}
  }, {
    breakpoint: 375,
    settings: {centerPadding: '80px'}
  },{
    breakpoint: 360,
    settings: {centerPadding: '85px'}
  }, {
    breakpoint: 340,
    settings: {centerPadding: '65px'}
  }],
};

const indexImages = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];

function FeedBannerView({handleGoToFeedClick}) {
  return <main className="feed-page">  
    <div className="container">
      <h4 dangerouslySetInnerHTML={{__html:i18n.t("feed_page_title")}} />
      <p className="subtitle">
        <span dangerouslySetInnerHTML={{__html:i18n.t("feed_page_subtitle")}} />
        <SvgSprite viewBox="0 0 122 80" icon="icon-toon-effect" />
      </p>
    </div>

    <section className="wr-slider-images">
      <Slider {...sliderSettings}>
        {indexImages.map((number) => <SlideView key={number} number={number} />)}
      </Slider>
    </section>

    <div className="container steps-container">
      <ul className="steps-list" onClick={handleGoToFeedClick}>
        <li className="step1">
          <span dangerouslySetInnerHTML={{__html:i18n.t("feed_page_step1")}} />
          <SvgSprite className="icon-step1" viewBox="0 0 33 13" icon="icon-step1" />
          <SvgSprite className="icon-step1-1" viewBox="0 0 180 37" icon="icon-step1-1" />
        </li>
        <li className="step2">
          <span dangerouslySetInnerHTML={{__html:i18n.t("feed_page_step2")}} />
          <SvgSprite viewBox="0 0 211 28" icon="icon-step2" />
        </li>
        <li className="step3">
          <span dangerouslySetInnerHTML={{__html:i18n.t("feed_page_step3")}} />
          <SvgSprite viewBox="0 0 116 57" icon="icon-step3" />
        </li>
      </ul>
       <button onClick={handleGoToFeedClick}>
        <span dangerouslySetInnerHTML={{__html:i18n.t("feed_page_btn_part1")}} />
        <SvgSprite viewBox="0 0 14 20" icon="icon-fire" />
        <span dangerouslySetInnerHTML={{__html:i18n.t("feed_page_btn_part2")}} />
      </button>
      <p className="feed-info" dangerouslySetInnerHTML={{__html:i18n.t("feed_page_info")}} />
      <BgView />
    </div>    
  </main>;
}

export default FeedBannerView;

function SlideView({number}) {
  const jpg = [
    assetUrl(`assets/img/slider/feed/img-${number}.jpg`) + " 1x",
    assetUrl(`assets/img/slider/feed/img-${number}@2x.jpg`) + " 2x",
    assetUrl(`assets/img/slider/feed/img-${number}@3x.jpg`) + " 3x"
  ].join(", ");

  const webp = [
    assetUrl(`assets/img/slider/feed/img-${number}.webp`) + " 1x",
    assetUrl(`assets/img/slider/feed/img-${number}@2x.webp`) + " 2x",
    assetUrl(`assets/img/slider/feed/img-${number}@3x.webp`) + " 3x"
  ].join(", ");

  return <div className="slide-container">
    <div className="slide">
      <picture>
        <source srcSet={webp} type="image/webp" />
        <img srcSet={jpg} alt="Demo" />
      </picture>
    </div>
  </div>;
}

function BgView() {
  const png = [
    assetUrl(`assets/img/bg/bgr.jpg`) + " 1x",
    assetUrl(`assets/img/bg/bgr@2x.jpg`) + " 2x",
    assetUrl(`assets/img/bg/bgr@3x.jpg`) + " 3x"
  ].join(", ");

  const webp = [
    assetUrl(`assets/img/bg/bgr.webp`) + " 1x",
    assetUrl(`assets/img/bg/bgr@2x.webp`) + " 2x",
    assetUrl(`assets/img/bg/bgr@3x.webp`) + " 3x"
  ].join(", ");

  return <picture>
    <source srcSet={webp} type="image/webp" />
    <img className="feed-bg" srcSet={png} alt="Demo" />
  </picture>;
}
