import React from "react";
import PropTypes from "prop-types";
import i18n from "../../i18n";
import SimpleTabDefault from "../result/SimpleTab";
import CreativeView from "../result/CreativeView";
import clientStorage from "../../utils/client-storage";
import {SvgSprite} from "../../components/SvgSprite";
import * as creativeUtils from "../../utils/creative";
import FileChooseButton from "../../components/FileChooseButton";

class SimpleTab extends SimpleTabDefault {

  render() {
    const { creatives } = this.props;
    const canBeRefreshed = !!this.props.canBeRefreshed;
    const group = creatives.filter((creative) => creative.group === this.props.group);

    const creative = group.find((creative) => creative.is_selected);
    const creativeIsProcessing = !creative || creativeUtils.creativeIsProcessing(creative);
    const creativeIsFailed = creative && creativeUtils.creativeIsFailed(creative);

    const previewCreative = group.find((creative) => !creative.is_selected);
    const previewCreativeIsProcessed = previewCreative && creativeUtils.creativeIsProcessed(previewCreative);

    const isNarrow = creative
      && creative.file
      && creative.file.height > creative.file.width;

    return <div>
      <div className={`creative-holder ${isNarrow ? "creative-holder--narrow" : ""}`}>
        {(creativeIsProcessing || creativeIsFailed) && <div className="creative-holder-placeholder" />}

        {previewCreativeIsProcessed && <div className="wait-video">
          <img src={previewCreative.file.url} alt="Creative" />
        </div>}

        {creative && creative.file.url && <React.Fragment>
          <CreativeView
            creative={creative}
            onLoaded={this.props.onImageLoaded} />
          {window.clientConfig.isPro && <div className="watermark-container" hidden={this.props.site === "love"}>
            <div hidden={clientStorage.getProWatermarkTooltipIsHidden()}>
              <div className="tooltip">
                <p dangerouslySetInnerHTML={{__html: i18n.t("toggle_watermark_tooltip__text")}} />
              </div>
              <div className="btn-remove-logo" hidden={clientStorage.getProWatermarkShouldBeRemoved()} onClick={this.props.onHideWatermarkButtonClick} />
            </div>
            <button
              className="btn-logo-on"
              hidden={!clientStorage.getProWatermarkShouldBeRemoved()}
              onClick={this.props.onShowWatermarkButtonClick}>
              <span children={i18n.t("toggle_watermark_on")} />
            </button>
            <button
              className="btn-logo-on"
              hidden={clientStorage.getProWatermarkShouldBeRemoved() || !clientStorage.getProWatermarkTooltipIsHidden()}
              onClick={this.props.onHideWatermarkButtonClick}>
              <span children={i18n.t("toggle_watermark_off")} />
            </button>
          </div>}
        </React.Fragment>}

        <div className="loader-roller-wrapper">
          <div className="loader-roller loader-roller1">
            <div className="roller" />
            <div className="roller" />
          </div>

          <div className="loader-roller loader-roller2">
            <div className="roller" />
            <div className="roller" />
          </div>

          <div className="loader-roller loader-roller3">
            <div className="roller" />
            <div className="roller" />
          </div>
        </div>
        <div className="square square-big" />
        <div className="square square-med" />
        <div className="square square-small" />
      </div>

      <div className="btns-container" hidden={creativeIsProcessing || creativeIsFailed}>
        <FileChooseButton
          onFileSelected={this.props.onFileSelected}
          className="btn-update btn-refresh-foto">
          <SvgSprite viewBox="0 0 24 24" icon="icon-magic" />
          <span children={i18n.t("try_another_photo")} />
        </FileChooseButton>
        <button
          className="btn-upload-foto"
          children={i18n.t("collage__download")}
          onClick={(e) => this.props.onDownloadButtonClick(e, creative, this.props.withHD)}>
          <div className="icon-container">
            <SvgSprite viewBox="0 0 24 24" icon="icon-download" />
          </div>
          <span children={i18n.t("collage__download")} />
        </button>
        {canBeRefreshed ? <button
          className="btn-update btn-refresh-foto"
          onClick={() => this.props.onRefreshButtonClick(this.props.group)}>
          <SvgSprite viewBox="0 0 24 24" icon="icon-refresh-v2" />
          <span children={i18n.t("collage__more_styles")} />
        </button> : <div className="btn-spacer" />}
      </div>
    </div>;
  }
}

SimpleTab.propTypes = {
  ...SimpleTab.propTypes,
  onFileSelected: PropTypes.func.isRequired
}

export default SimpleTab;
