import React from 'react';
import AppContext from "../contexts/AppContext";
import {creativesConfigs} from "../photolab/config";
import {creativeGroups} from "../photolab/groups";
import ImageView from "../components/ImageView";
import {assetUrl} from "../utils/etc";

export default class TemplatesPreviewPage extends React.Component {

  state = {
    groups: [],
  };

  componentDidMount() {
    const groups = Object.values(creativeGroups).slice();

    const HDpos = groups.indexOf(creativeGroups.HD);
    if (HDpos >= 0) {
      groups.splice(HDpos, 1);
    }

    const collagePos = groups.indexOf(creativeGroups.COLLAGE);
    if (collagePos >= 0) {
      groups.splice(collagePos, 1);
    }

    this.setState({groups})
  }

  render() {
    return <div>
      {this.state.groups.map((group) => {
        return <div style={{margin: 40, padding: "20px 20px 0 20px", border: "2px solid #000", background: "#eee"}}>
          <b>{group}</b>
          <hr />
          <div style={{display: "flex", flexWrap: "wrap"}}>
            {creativesConfigs.filter((config) => config.group === group).map((config) => {
              const key = `${config.group}_${config.templateId}`;
              return <div style={{width: 300, margin: "0 10px 20px 0"}}>
                <b>{config.templateId}</b>
                <ImageView image={{url: assetUrl(`assets/templates-samples/${key}.jpg`)}} square />
              </div>;
            })}
          </div>
        </div>
      })}

    </div>
  }
}

TemplatesPreviewPage.contextType = AppContext;
